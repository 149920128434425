import { useEffect, useState } from 'react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Chip, IconButton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ko';
import isBetweenPlugin from 'dayjs/plugin/isBetween';

import { useSnackbar } from '../../components/snackbar';

dayjs.extend(isBetweenPlugin);
const locale = 'ko';

/** Custom Day */
interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
  isSingleDay: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay, isSingleDay }) => {
  const leftRadius = { borderTopLeftRadius: '50%', borderBottomLeftRadius: '50%' };
  const rightRadius = { borderTopRightRadius: '50%', borderBottomRightRadius: '50%' };
  const isSelected = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  };

  return {
    ...(dayIsBetween && {
      borderRadius: 0,
      ...isSelected,
    }),
    ...(isFirstDay && leftRadius),
    ...(isLastDay && rightRadius),
    ...(isSingleDay && {
      ...leftRadius,
      ...rightRadius,
      ...isSelected,
    }),
  };
}) as React.ComponentType<CustomPickerDayProps>;

/**NewDateRangePicker */
interface NewDateRangePickerProps {
  cs?: boolean;
  canSearchRecent90Dates?: boolean;
  initialStartDate: string;
  initialEndDate: string;
  isLoading?: boolean;
  setParam: (params: { startDate: string; endDate: string }) => void;
  _minDate?: string;
}

export function NewDateRangePicker({
  cs,
  canSearchRecent90Dates,
  initialStartDate,
  initialEndDate,
  isLoading = false,
  setParam,
  _minDate = '2018-01-01',
}: NewDateRangePickerProps) {
  const [tempDuration, setTempDuration] = useState<(Dayjs | null)[]>([null, null]);
  const [tempStartDate, tempEndDate] = tempDuration;
  const [_, setSnackbar] = useSnackbar();

  const [duration, setDuration] = useState<(Dayjs | null)[]>([
    initialStartDate ? dayjs(initialStartDate) : dayjs(new Date()),
    initialEndDate ? dayjs(initialEndDate) : dayjs(new Date()),
  ]);
  const [startDate, endDate] = duration;

  const [calendarViewMonth, setCalendarViewMonth] = useState(dayjs(new Date()));

  const [calendarOpen, setCalendarOpen] = useState(false);

  useEffect(() => {
    if (startDate && endDate && tempStartDate && tempEndDate) {
      setCalendarOpen(false);

      if (canSearchRecent90Dates && dayjs(endDate).subtract(89, 'day') > startDate) {
        setDuration([dayjs(initialStartDate), dayjs(initialEndDate)]);
        return setSnackbar({
          open: true,
          severity: 'error',
          message: '최대 3개월 이내만 조회 가능합니다.',
        });
      }
      return setParam({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      });
    }
  }, [startDate, endDate, tempStartDate, tempEndDate]);

  const CustomDateRangePicker = () => {
    return (
      <Stack>
        <Stack flexDirection="row" position="relative">
          <DatePickerHeader />
          <CustomDatePicker defaultCalendarMonth={calendarViewMonth.subtract(1, 'M')} />
          <Stack
            position="absolute"
            width="1px"
            height={'100%'}
            top={0}
            zIndex={10}
            right={'50%'}
            bgcolor="lightgray"
          />
          <CustomDatePicker defaultCalendarMonth={calendarViewMonth} />
        </Stack>
        <DurationChips />
      </Stack>
    );
  };

  const DatePickerHeader = () => {
    const LeftArrowButtons = () => {
      return (
        <Stack flexDirection="row">
          <IconButton
            sx={{ padding: 0 }}
            disableRipple
            onClick={() => {
              setCalendarViewMonth(calendarViewMonth.subtract(1, 'y'));
            }}
          >
            <KeyboardDoubleArrowLeftIcon />
          </IconButton>
          <IconButton
            sx={{ padding: 0 }}
            disableRipple
            onClick={() => {
              setCalendarViewMonth(calendarViewMonth.subtract(1, 'M'));
            }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography alignSelf="center" fontWeight="bold" paddingX={10}>
            {calendarViewMonth.subtract(1, 'M').format('YYYY년 MM월')}
          </Typography>
        </Stack>
      );
    };

    const RightArrowButtons = () => {
      return (
        <Stack flexDirection="row">
          <Typography alignSelf="center" fontWeight="bold" paddingX={10}>
            {calendarViewMonth.format('YYYY년 MM월')}
          </Typography>
          <IconButton
            sx={{ padding: 0 }}
            disableRipple
            onClick={() => {
              setCalendarViewMonth(calendarViewMonth.add(1, 'M'));
            }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
          <IconButton
            sx={{ padding: 0 }}
            disableRipple
            onClick={() => {
              setCalendarViewMonth(calendarViewMonth.add(1, 'y'));
            }}
          >
            <KeyboardDoubleArrowRightIcon />
          </IconButton>
        </Stack>
      );
    };

    return (
      <Stack
        position="absolute"
        sx={{
          paddingX: 6,
          backgroundColor: ({ palette }) => (palette.mode === 'light' ? 'grey.50' : 'darkGrey.800'),
          zIndex: 5,
        }}
        width="100%"
        height="60px"
        justifyContent="space-between"
        flexDirection="row"
      >
        <LeftArrowButtons />
        <RightArrowButtons />
      </Stack>
    );
  };

  /** CustomDatePicker */
  const CustomDatePicker = (props: { defaultCalendarMonth: Dayjs }) => {
    const minDate = dayjs(_minDate);

    const handleDateChange = (date: Dayjs | null) => {
      if (!date) return;

      if (!tempStartDate) {
        setTempDuration([date, null]);
      } else if (tempStartDate) {
        setTempDuration((prev) => [prev[0], date]);
      }

      if ((startDate && date.isSame(startDate)) || (endDate && date.isSame(endDate))) {
        return setDuration([date, date]);
      }

      if ((startDate && date.isBefore(startDate)) || (endDate && date.isAfter(endDate))) {
        return setDuration([date, null]);
      }

      if (startDate && endDate && date.isAfter(startDate) && date.isBefore(endDate)) {
        return setDuration(!tempStartDate ? [date, endDate] : [startDate, date]);
      }

      if (startDate && date.isAfter(startDate)) {
        return setDuration([startDate, date]);
      }
    };

    return (
      <StaticDatePicker
        {...props}
        minDate={minDate}
        disableFuture
        views={['day']}
        disableHighlightToday
        displayStaticWrapperAs="desktop"
        value={null}
        components={{
          LeftArrowButton: () => null,
          RightArrowButton: () => null,
        }}
        onChange={handleDateChange}
        renderDay={renderDay}
        renderInput={(params) => <TextField {...params} label="yyyy-mm-dd" />}
      />
    );
  };

  /** RenderDay */
  const renderDay = (
    date: Dayjs,
    selectedDates: Array<Dayjs | null>,
    pickersDayProps: PickersDayProps<Dayjs>
  ) => {
    const dayIsBetween = startDate && endDate && date.isBetween(startDate, endDate, 'day', '[]');

    const isFirstDay = date.isSame(startDate, 'day');
    const isLastDay = date.isSame(endDate, 'day');
    const isSingleDay = (isFirstDay && !endDate) || (isLastDay && !startDate);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={!!dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
        isSingleDay={isSingleDay}
      />
    );
  };

  /** DurationChips */
  const DurationChips = () => {
    const DURATION_TYPES = ['오늘', '어제', '최근 7일', '최근 30일', '이번 달', '지난 달'] as const;
    const CS_DURATION_TYPES = [
      '오늘',
      '어제',
      '최근 3일',
      '최근 7일',
      '최근 14일',
      '최근 30일',
    ] as const;
    type DurationType = typeof DURATION_TYPES[number];
    type CSDurationType = typeof CS_DURATION_TYPES[number];

    const handleSelectDuration = (duration: DurationType | CSDurationType) => {
      const today = dayjs(new Date());
      const getDuration = () => {
        switch (duration) {
          case '오늘':
            return [today, today];
          case '어제':
            return [today.subtract(1, 'day'), today.subtract(1, 'day')];
          case '최근 3일':
            return cs
              ? [today.subtract(3, 'day'), today.subtract(1, 'day')]
              : [today.subtract(2, 'day'), today];
          case '최근 7일':
            return cs
              ? [today.subtract(7, 'day'), today.subtract(1, 'day')]
              : [today.subtract(6, 'day'), today];
          case '최근 14일':
            return cs
              ? [today.subtract(14, 'day'), today.subtract(1, 'day')]
              : [today.subtract(13, 'day'), today];
          case '최근 30일':
            return cs
              ? [today.subtract(30, 'day'), today.subtract(1, 'day')]
              : [today.subtract(29, 'day'), today];
          case '지난 달':
            return [
              today.subtract(1, 'month').startOf('month'),
              today.subtract(1, 'month').endOf('month'),
            ];
          case '이번 달':
            return [today.startOf('month'), today];
          default:
            return [today, null];
        }
      };

      const durationVal = getDuration();

      setDuration(durationVal);
      setTempDuration(durationVal);
      setCalendarViewMonth(dayjs(new Date()));
    };

    return (
      <Stack
        direction="row"
        justifyContent="right"
        width="100%"
        alignContent="center"
        spacing={3}
        sx={{
          borderTop: '1px solid lightgrey',
          padding: 2,
        }}
      >
        {(cs ? CS_DURATION_TYPES : DURATION_TYPES).map((duration) => (
          <Chip
            key={duration}
            clickable
            sx={{ padding: 0 }}
            color="primary"
            onClick={() => {
              handleSelectDuration(duration);
            }}
            label={duration}
            variant="outlined"
          />
        ))}
      </Stack>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <DatePicker
        disableFuture
        InputProps={{
          sx: { width: '220px', height: '34px', fontSize: 'small' },
        }}
        components={{ PaperContent: CustomDateRangePicker }}
        value={null}
        disabled={!!isLoading}
        open={calendarOpen}
        onOpen={() => {
          setCalendarOpen(true);
          setTempDuration([null, null]);
          setCalendarViewMonth(dayjs(new Date()));
        }}
        onClose={() => {
          setCalendarOpen(false);
        }}
        onChange={() => null}
        renderInput={(params) => {
          const newParams = {
            ...params,
            inputProps: {
              ...params.inputProps,
              readOnly: true,
              value: `${startDate?.format('YYYY-MM-DD') || ''} ~ ${
                endDate?.format('YYYY-MM-DD') || ''
              }`,
            },
          };

          return (
            <TextField
              sx={{ input: { cursor: 'pointer' } }}
              label="yyyy-mm-dd"
              {...newParams}
              onClick={() => {
                if (!isLoading) {
                  setTempDuration([null, null]);
                  setCalendarViewMonth(dayjs(new Date()));
                  setCalendarOpen(true);
                }
              }}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
}

export default NewDateRangePicker;
