import { UpdateCampaignDetailStep } from './types';

export const routes = {
  dashboard: {
    path: '/dashboard',
    name: '대시보드',
  },
  // 캠페인
  campaign: {
    path: '/campaign',
    name: '캠페인',
  },
  campaignManagement: {
    path: '/campaign/management',
    name: '캠페인 관리',
  },
  campaignMediaManagement: {
    path: '/campaign/management/:campaignKey/media',
    name: '노출 매체',
    getPath: (campaignKey: string | number) => `/campaign/management/${campaignKey}/media`,
  },
  updateCampaignDetail: {
    path: '/campaign/management/:campaignKey/update/:step',
    name: '캠페인 업데이트',
    getPath: (campaignKey: string | number, step = UpdateCampaignDetailStep.CampaignSetting) =>
      `/campaign/management/${campaignKey}/update/${step}`,
  },
  externalCampaign: {
    path: '/campaign/external',
    name: '타 네트워크 캠페인',
  },
  shoppingCampaign: {
    path: '/campaign/shopping',
    name: '쇼핑 네트워크 캠페인',
  },
  removedCampaign: {
    path: '/campaign/removed',
    name: '삭제 캠페인',
  },
  campaignStatus: {
    path: '/campaign/status',
    name: '캠페인 변경 사항 관리',
  },
  campaignStatusManagement: {
    path: '/campaign/status/:changeRequestNo',
    name: '캠페인 변경 사항 상세',
    getPath: (changeRequestNo: string | number) => `/campaign/status/${changeRequestNo}`,
  },
  // 매체
  media: {
    path: '/media',
    name: '매체',
  },
  mediaManagement: {
    path: '/media/management',
    name: '매체 관리',
  },
  mediaCampaignManagement: {
    path: '/media/management/:mediaKey/campaign',
    name: '노출 캠페인',
    getPath: (mediaKey: number) => `/media/management/${mediaKey}/campaign`,
  },
  mediaApproval: {
    path: '/media/approval',
    name: '매체 승인',
  },
  /**매체 지면관리 */
  mediaPlacement: {
    path: '/media/placementManagement',
    name: '매체 지면 관리',
  },
  updateMediaPlacement: {
    path: '/media/placementManagement/:bridgePlacementId',
    name: '매체 지면 업데이트',
    getPath: (bridgePlacementId: string) => `/media/placementManagement/${bridgePlacementId}`,
  },
  createMediaPlacement: {
    path: '/media/placementManagement/create',
    name: '지면 등록',
  },
  mediaApprovalDetail: {
    path: '/media/approval/:approvalRequestNo',
    name: '검수 정보',
    getPath: (approvalRequestNo: number) => `/media/approval/${approvalRequestNo}`,
  },
  mediaPriceManagement: {
    path: '/media/price-management',
    getPath: (mediaKey: number) => `/media/price-management/${mediaKey}`,
    name: '매체 단가 관리',
  },
  mediaPlatformManagement: {
    path: '/media/platform-management',
    name: '플랫폼 관리',
  },
  // 운영
  operation: {
    path: '/operation',
    name: '운영',
  },
  monitoring: {
    path: '/operation/monitoring',
    name: '모니터링',
  },
  monitoringDetail: {
    path: '/operation/monitoring/:campaignKey',
    name: '모니터링 상세 보기',
    getPath: (campaignKey: string) => `/operation/monitoring/${campaignKey}`,
  },
  priorityManagement: {
    path: '/operation/priority-management',
    name: '우선순위 관리',
  },
  targetingManagement: {
    path: '/operation/targeting-management',
    name: '타겟팅 관리',
  },
  tagManagement: {
    path: '/operation/tag-management',
    name: '태그 관리',
  },
  newsWebtoonRcpmManagement: {
    path: '/operation/news-webtoon-rcpm-management',
    name: '외부 연동 관리',
  },
  whiteAdidManagement: {
    path: '/operation/white-adid-management',
    name: 'White ADID 관리',
  },
  gameRangking: {
    path: '/operation/game-ranking',
    name: '게임 랭킹 조회',
  },
  // 리포트
  report: {
    path: '/report',
    name: '리포트',
  },
  salesReport: {
    path: '/report/sales',
    name: '매출 리포트',
  },
  campaignReport: {
    path: '/report/campaign',
    name: '캠페인 리포트',
  },
  campaignReportDetail: {
    path: '/report/campaign/:campaignKey',
    name: '캠페인 리포트 상세 보기',
    getPath: (campaignKey: string) => `/report/campaign/${campaignKey}`,
  },
  mediaReport: {
    path: '/report/media',
    name: '매체 리포트',
  },
  mediaReportDetail: {
    path: '/report/media/:mediaKey',
    name: '매체 리포트 상세 보기',
    getPath: (mediaKey: number) => `/report/media/${mediaKey}`,
  },
  // 정산
  settlement: {
    path: '/settlement',
    name: '정산',
  },
  campaignSettlement: {
    path: '/settlement/campaign',
    name: '캠페인 정산',
  },
  companySettlement: {
    path: '/settlement/company',
    name: '매체 정산',
  },
  companySettlementDetail: {
    path: '/settlement/company/:companyKey',
    name: '정산 상세',
    getPath: (companyKey: number) => `/settlement/company/${companyKey}`,
  },
  paymentRequestDetail: {
    path: '/settlement/company/payment-request-detail',
    name: '출금 신청 상세',
  },
  companyRevenueDetail: {
    path: '/settlement/revenue-detail',
    name: '매체 수익 상세',
  },
  // CS
  cs: {
    path: '/cs',
    name: 'CS',
  },
  csManagement: {
    path: '/cs/management',
    name: 'CS 관리',
  },
  csDetail: {
    path: '/cs/management/:csNo',
    name: 'CS 상세',
    getPath: (csNo: number) => `/cs/management/${csNo}`,
  },
  bulkCSManagement: {
    path: '/cs/bulk-management',
    name: '대량 CS 처리',
  },
  csDashboard: {
    path: '/cs/dashboard',
    name: 'CS 대시보드',
  },
  csParticipationManagement: {
    path: '/cs/participation',
    name: '참여 정보 관리',
  },
  qaParticipationCancellation: {
    path: '/cs/qa-participation',
    name: 'QA 참여 정보 삭제',
  },
  // 관리
  management: {
    path: '/management',
    name: '관리',
  },
  companyAndCustomer: {
    path: '/management/company-and-customer',
    name: '업체 및 회원 관리',
  },
  faqContent: {
    path: '/management/faq-content',
    name: '고객센터(SDK) 관리',
  },
  // 로그
  log: {
    path: '/log',
    name: '로그',
  },
  systemLog: {
    path: '/log/system',
    name: '시스템 로그',
  },
  campaignLog: {
    path: '/log/campaign',
    name: '캠페인 로그',
  },
  // 로그인
  login: {
    path: '/login',
    name: '로그인',
  },
  // 홈
  root: {
    path: '/',
    name: 'Home',
  },
} as const;
