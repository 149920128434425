import { Box, FormControl, FormLabel, Stack, TextField, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import ModalButtonGroup from 'components/modal-button-group';
import { useTypedFormik } from 'hooks';
import { fullWidth, SSO } from 'styles';

import * as c from './const';
import { ChangePasswordFormProps } from './types';

const labelStyle: SSO = {
  mb: 1,
  color: 'text.primary',
};

const textFieldStyle: SSO[] = [
  fullWidth,
  {
    '.MuiInputBase-input': {
      p: 3,
    },
  },
];

const ChangePasswordForm = (props: ChangePasswordFormProps) => {
  const { initialValues, onSubmit, onCancel, loading } = props;

  const { handleSubmit, shouldErrorShows, getHelperText, getFieldProps, isValid } = useTypedFormik({
    initialValues,
    onSubmit,
    validationSchema: c.changePasswordFormValidationSchema,
  });

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack component="fieldset">
        <Typography component="legend" sx={visuallyHidden} color="GrayText" fontSize="h6">
          비밀번호 변경 폼
        </Typography>
        <Stack spacing={4}>
          <FormControl>
            <FormLabel htmlFor={c.inputIdByField.userId} required sx={labelStyle}>
              이메일
            </FormLabel>
            <TextField
              id={c.inputIdByField.userId}
              type="email"
              placeholder={c.chagnePasswordFormValidationMessages.userId.required}
              variant="outlined"
              autoComplete="off"
              error={shouldErrorShows('userId')}
              helperText={getHelperText('userId')}
              sx={textFieldStyle}
              {...getFieldProps('userId')}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor={c.inputIdByField.oldPassword} required sx={labelStyle}>
              기존 비밀번호
            </FormLabel>
            <TextField
              id={c.inputIdByField.oldPassword}
              type="password"
              placeholder={c.chagnePasswordFormValidationMessages.oldPassword.required}
              variant="outlined"
              error={shouldErrorShows('oldPassword')}
              helperText={getHelperText('oldPassword')}
              sx={textFieldStyle}
              {...getFieldProps('oldPassword')}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor={c.inputIdByField.newPassword} required sx={labelStyle}>
              신규 비밀번호
            </FormLabel>
            <TextField
              id={c.inputIdByField.newPassword}
              type="password"
              placeholder={c.chagnePasswordFormValidationMessages.newPassword.required}
              variant="outlined"
              error={shouldErrorShows('newPassword')}
              helperText={getHelperText('newPassword')}
              sx={textFieldStyle}
              {...getFieldProps('newPassword')}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor={c.inputIdByField.newPasswordConfirm} required sx={labelStyle}>
              신규 비밀번호 확인
            </FormLabel>
            <TextField
              id={c.inputIdByField.newPasswordConfirm}
              type="password"
              placeholder={c.chagnePasswordFormValidationMessages.newPasswordConfirm.required}
              variant="outlined"
              error={shouldErrorShows('newPasswordConfirm')}
              helperText={getHelperText('newPasswordConfirm')}
              sx={textFieldStyle}
              {...getFieldProps('newPasswordConfirm')}
            />
          </FormControl>
          <ModalButtonGroup
            type="submit"
            confirmButtonText="저장"
            loading={loading}
            disableConfirm={!isValid}
            onCancel={onCancel}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default ChangePasswordForm;
