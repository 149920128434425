import { get, post } from 'api/request';

import * as Types from './types';

export default class OperationAPI {
  /**
   * 우선 순위 캠페인 목록 조회
   */
  static getPriorityCampaignList(token: string) {
    return get<Types.PriorityCampaignListResponseSuccess>(
      '/api/v1/operation/manage/PriorityCampaignList',
      token
    );
  }

  /**
   * 우선 순위 캠페인 목록 업데이트
   */
  static updatePriorityCampaignList(token: string, data: Types.UpdatePriorityCampaignListData) {
    return post('/api/v1/operation/manage/UpdatePriorityCampaignList', data, token);
  }

  /**
   * 모니터링 목록 조회
   */
  static getMonitoringList(token: string, params: Types.MonitoringListParams) {
    return get<Types.MonitoringListResponseSuccess, Types.MonitoringListParams>(
      '/api/v1/operation/manage/MonitoringList',
      token,
      params
    );
  }

  /**
   * 모니터링 상세(캠페인 진행률) 조회
   */
  static getCampaignTransactionInfo(token: string, params: Types.CampaignTransactionInfoParams) {
    return get<Types.CampaignTransactionInfoResponseSuccess, Types.CampaignTransactionInfoParams>(
      '/api/v1/operation/manage/CampaignTransactionInfo',
      token,
      params
    );
  }

  /**
   * 모니터링 상세(전체, 일 별, 매체 별, 시간 별) 조회
   */
  static getMonitoringDetail<T extends Types.MonitoringType>(
    token: string,
    params: Types.MonitoringDetailParams
  ) {
    return get<Types.MonitoringDetailResponseSuccess<T>, Types.MonitoringDetailParams>(
      '/api/v1/operation/manage/MonitoringDetail',
      token,
      params
    );
  }

  /**
   * 일 별 모니터링 - 노출 매체 조회
   */
  static getDailyMediaMonitoring(token: string, params: Types.DailyMediaMonitoringParams) {
    return get<Types.DailyMediaMonitoringResponseSuccess, Types.DailyMediaMonitoringParams>(
      '/api/v1/operation/manage/DailyCampaignMediaMonitoring',
      token,
      params
    );
  }

  /**
   * 매체 별 모니터링 - 노출 매체 조회
   */
  static getMediaDetailMonitoringList(
    token: string,
    params: Types.MediaDetailMonitoringListParams
  ) {
    return get<
      Types.MediaDetailMonitoringListResponseSuccess,
      Types.MediaDetailMonitoringListParams
    >('/api/v1/operation/manage/MediaCampaignMonitoringList', token, params);
  }

  /**
   * 시간 별 모니터링 - 차트 데이터 조회
   */
  static getHourlyMonitoringChart(token: string, params: Types.HourlyMonitoringChartParams) {
    return get<Types.HourlyMonitoringChartResponseSuccess, Types.HourlyMonitoringChartParams>(
      '/api/v1/operation/manage/DailyMonitoringChart',
      token,
      params
    );
  }

  /**
   * 타겟팅 관리 리스트 조회
   */
  static getCommonTargetGroupList(token: string) {
    return get<Types.CommonTargetGroupListResponseSuccess>(
      '/api/v1/operation/manage/CommonTargetGroupList',
      token
    );
  }

  /**
   * 타겟 그룹 이름 업데이트
   */
  static updateTargetGroupName(token: string, data: Types.UpdateTargetGroupNameData) {
    return post('/api/v1/operation/manage/UpdateTargetGroupName', data, token);
  }

  /**
   * 타겝 그룹 등록
   */
  static createTargetGroup(token: string, data: Types.CreateTargetGroupData) {
    return post('/api/v1/operation/manage/CreateTargetGroup', data, token);
  }

  /**
   * 타겟 추가 - 기존 타겟 그룹에 단일 adid or idfa 추가
   */
  static updateTargetUserIdentifier(token: string, data: Types.UpdateTargetUserIdentifierData) {
    return post('/api/v1/operation/manage/UpdateTargetUserIdentifier', data, token);
  }

  /**
   * 타겟 추가 - 기존 타겟 그룹에 파일 추가
   */
  static updateTargetUserFile(token: string, data: FormData) {
    return post('/api/v1/operation/manage/UpdateTargetUserFile', data, token);
  }

  /**
   * 타겟팅 관리 -> 연결 캠페인 목록 조회
   */
  static getTargetGroupDetail(token: string, params: Types.TargetGroupDetailInfoParams) {
    return get<Types.TargetGroupDetailInfoResponseSuccess, Types.TargetGroupDetailInfoParams>(
      '/api/v1/operation/manage/TargetGroupDetailInfo',
      token,
      params
    );
  }

  /**
   * 캠페인 태그 조회 (운영)
   */
  static getCampaignTagList(token: string) {
    return get<Types.O_CampaignTagListResponseSuccess>(
      '/api/v1/operation/manage/campaignTagList',
      token
    );
  }

  /**
   * 캠페인 태그 생성
   */
  static createCampaignTag(token: string, data: Types.CreateCampaignTagData) {
    return post('/api/v1/operation/manage/createCampaignTag', data, token);
  }

  /**
   * 캠페인 태그 삭제
   */
  static deleteCampaignTag(token: string, data: Types.DeleteCampaignTagData) {
    return post('/api/v1/operation/manage/deleteCampaignTagList', data, token);
  }

  /**
   * 캠페인 태그 - 연결 캠페인 조회
   */
  static getConnectedCampaignList(token: string, params: Types.ConnectedCampaignListParams) {
    return get<Types.ConnectedCampaignListResponseSuccess, Types.ConnectedCampaignListParams>(
      '/api/v1/operation/manage/TagConnectedCampaignList',
      token,
      params
    );
  }

  /**
   * 캠페인 태그 - 연결 캠페인 삭제
   */
  static deleteCampaignTagMatchInfo(token: string, data: Types.DeleteCampaignTagMatchInfoData) {
    return post('/api/v1/operation/manage/deleteCampaignTagMatchInfo', data, token);
  }

  /**
   * 매체 태그 조회 (운영)
   */
  static getMediaTagList(token: string) {
    return get<Types.O_MediaTagListResponseSuccess>('/api/v1/operation/manage/mediaTagList', token);
  }

  /**
   * 매체 태그 생성
   */
  static createMediaTag(token: string, data: Types.CreateMediaTagData) {
    return post('/api/v1/operation/manage/createMediaTag', data, token);
  }

  /**
   * 매체 태그 삭제
   */
  static deleteMediaTag(token: string, data: Types.DeleteMediaTagData) {
    return post('/api/v1/operation/manage/deleteMediaTagList', data, token);
  }

  /**
   * 매체 태그 - 연결 매체 조회
   */
  static getConnectedMediaList(token: string, params: Types.ConnectedMediaListParams) {
    return get<Types.ConnectedMediaListResponseSuccess, Types.ConnectedMediaListParams>(
      '/api/v1/operation/manage/TagConnectedMediaList',
      token,
      params
    );
  }

  /**
   * 연결 매체 삭제
   */
  static deleteMediaTagMatchInfo(token: string, data: Types.DeleteMediaTagMatchInfoData) {
    return post('/api/v1/operation/manage/deleteMediaTagMatchInfo', data, token);
  }

  /**
   * 뉴스적립 네트워크 목록 조회
   */
  static getNewsNetworkList(token: string) {
    return get<Types.NewsNetworkListResponseSuccess>(
      '/api/v1/operation/manage/NewsNetworkList',
      token
    );
  }

  /**
   * 뉴스적립 - 연동 매체 목록 조회
   */
  static getIntegratedNewsMediaList(token: string, params: Types.IntegratedNewsMediaListParams) {
    return get<Types.IntegratedNewsMediaListResponseSuccess, Types.IntegratedNewsMediaListParams>(
      '/api/v1/operation/manage/IntegratedNewsMediaList',
      token,
      params
    );
  }

  /**
   * 뉴스적립 - 연동 매체 상태 업데이트
   */
  static updateMediaNewsIntegrationStatus(
    token: string,
    data: Types.UpdateMediaNewsIntegrationStatusData
  ) {
    return post('/api/v1/operation/manage/UpdateMediaNewsIntegrationStatus', data, token);
  }

  /**
   * 뉴스적립 네트워크 - 연동 가능한 매체 목록 조회
   */
  static getAvailableNewsMediaList(token: string) {
    return get<Types.AvailableNewsMediaListResponseSuccess>(
      '/api/v1/operation/manage/AvailableNewsMediaList',
      token
    );
  }

  /**
   * 간편적립 네트워크 목록 조회
   */
  static getRCPMNetworkList(token: string) {
    return get<Types.RCPMNetworkListResponseSuccess>(
      '/api/v1/operation/manage/RCPMNetworkList',
      token
    );
  }

  /**
   * 간편적립 - 연동 매체 목록 조회
   */
  static getIntegratedRCPMMediaList(token: string, params: Types.IntegratedNewsMediaListParams) {
    return get<Types.IntegratedRCPMMediaListResponseSuccess, Types.IntegratedRCPMMediaListParams>(
      '/api/v1/operation/manage/IntegratedRCPMMediaList',
      token,
      params
    );
  }

  /**
   * 간편적립 - 연동 매체 상태 업데이트
   */
  static updateMediaRCPMIntegrationStatus(
    token: string,
    data: Types.UpdateMediaRCPMIntegrationStatusData
  ) {
    return post('/api/v1/operation/manage/UpdateMediaRCPMIntegrationStatus', data, token);
  }

  /**
   * 간편적립 네트워크 - 연동 가능한 매체 목록 조회
   */
  static getAvailableRCPMMediaList(token: string) {
    return get<Types.AvailableRCPMMediaListResponseSuccess>(
      '/api/v1/operation/manage/AvailableRCPMMediaList',
      token
    );
  }

  /**
   * 웹툰적립 네트워크 목록 조회
   */
  static getWebtoonNetworkList(token: string) {
    return get<Types.WebtoonNetworkListResponseSuccess>(
      '/api/v1/operation/manage/WebtoonNetworkList',
      token
    );
  }

  /**
   * 웹툰적립 - 연동 매체 목록 조회
   */
  static getIntegratedWebtoonMediaList(
    token: string,
    params: Types.IntegratedWebtoonMediaListParams
  ) {
    return get<
      Types.IntegratedWebtoonMediaListResponseSuccess,
      Types.IntegratedWebtoonMediaListParams
    >('/api/v1/operation/manage/IntegratedWebtoonMediaList', token, params);
  }

  /**
   * 웹툰적립 - 연동 매체 상태 업데이트
   */
  static updateMediaWebtoonIntegrationStatus(
    token: string,
    data: Types.UpdateMediaWebtoonIntegrationStatusData
  ) {
    return post('/api/v1/operation/manage/UpdateMediaWebtoonIntegrationStatus', data, token);
  }

  /**
   * 웹툰적립 네트워크 - 연동 가능한 매체 목록 조회
   */
  static getAvailableWebtoonMediaList(token: string) {
    return get<Types.AvailableWebtoonMediaListResponseSuccess>(
      '/api/v1/operation/manage/AvailableWebtoonMediaList',
      token
    );
  }

  /**
   * RV 네트워크 목록 조회
   */
  static getRVNetworkList(token: string) {
    return get<Types.RVNetworkListResponseSuccess>('/api/v1/operation/manage/RVNetworkList', token);
  }

  /**
   * RV - 연동 매체 목록 조회
   */
  static getIntegratedRVMediaList(token: string, params: Types.IntegratedRVMediaListParams) {
    return get<Types.IntegratedRVMediaListResponseSuccess, Types.IntegratedRVMediaListParams>(
      '/api/v1/operation/manage/IntegratedRVMediaList',
      token,
      params
    );
  }

  /**
   * RV - 연동 매체 상태 업데이트
   */
  static updateMediaRVIntegrationStatus(
    token: string,
    data: Types.UpdateMediaRVIntegrationStatusData
  ) {
    return post('/api/v1/operation/manage/UpdateMediaRVIntegrationStatus', data, token);
  }

  /**
   * RV 네트워크 - 연동 가능한 매체 목록 조회
   */
  static getAvailableRVMediaList(token: string) {
    return get<Types.AvailableRVMediaListResponseSuccess>(
      '/api/v1/operation/manage/AvailableRVMediaList',
      token
    );
  }

  /**
   * White Adid 조회
   */
  static checkWhiteAdid(token: string, params: Types.CheckWhiteAdidParams) {
    return get<Types.CheckWhiteAdidResponseSuccess, Types.CheckWhiteAdidParams>(
      '/api/v1/operation/manage/IsWhiteAdid',
      token,
      params
    );
  }

  /**
   * White Adid 등록
   */
  static addWhiteAdid(token: string, data: Types.AddWhiteAdidData) {
    return post('/api/v1/operation/manage/AddwhiteAdid', data, token);
  }

  /**
   * 게임 랭킹
   */
  static getGameRanking(token: string, params: Types.GetGameRankingParams) {
    return get<Types.GetGameRankingListSuccess, Types.GetGameRankingParams>(
      '/api/v1/dashboard/dashboard/GameRanking',
      token,
      params
    );
  }

  static getDetailGameTitleList(token: string, params: Types.GetDetailGameTitleListParams) {
    return get<Types.GetDetailGameTitleListSuccess, Types.GetDetailGameTitleListParams>(
      '/api/v1/dashboard/dashboard/SearchGame',
      token,
      params
    );
  }

  static getDetailGameRankingList(token: string, params: Types.GetDetailGameRankingListParams) {
    return get<Types.GetDetailGameRankingListSuccess, Types.GetDetailGameRankingListParams>(
      '/api/v1/dashboard/dashboard/DetailGameRanking',
      token,
      params
    );
  }
}
