import { get, post } from 'api/request';

import * as Types from './types';

export default class CSAPI {
  /**
   * CS 관리 페이지 - CS 목록 조회
   */
  static getCSList(token: string, params: Types.CSListParams) {
    return get<Types.CSListResponseSuccess, Types.CSListParams>(
      '/api/v1/cs/manage/CSListV2',
      token,
      params
    );
  }

  /**
   * CS 관리 페이지 - CS 회신 요청
   */
  static replyCS(token: string, data: Types.ReplyCSData) {
    return post<Types.ReplyCSResponseSuccess, Types.ReplyCSData>(
      '/api/v1/cs/manage/replyCS',
      data,
      token
    );
  }

  /**
   * CS 관리 페이지 - 리워드 지급 요청
   */
  static completeReward(token: string, data: Types.CompleteRewardData) {
    return post<Types.CompleteRewardResponseSuccess, Types.CompleteRewardData>(
      '/api/v1/cs/manage/completeRewardByCsNo',
      data,
      token
    );
  }

  /**
   * CS 상세 페이지 - CS 상세 조회
   */
  static getCSDetail(token: string, params: Types.CSDetailParams) {
    return get<Types.CSDetailResponseSuccess, Types.CSDetailParams>(
      '/api/v1/cs/manage/csInfo',
      token,
      params
    );
  }

  /**
   * CS 상세 페이지 - 처리 완료 상태 변경
   */
  static UpdateStatusByCsNo(token: string, data: Types.UpdateStatusByCsNoData) {
    return post('/api/v1/cs/manage/UpdateStatusByCsNo', data, token);
  }

  /**
   * CS 상세 페이지 - 참여 완료 정보 조회
   */
  static getParticipationHistoryList(token: string, params: Types.ParticipationHistoryListParams) {
    return get<Types.ParticipationHistoryListResponseSuccess, Types.ParticipationHistoryListParams>(
      '/api/v1/cs/manage/csParticipationInfoList',
      token,
      params
    );
  }

  /**
   * CS 상세 페이지 - transactionNo 이용한 리워드 지급 요청
   */
  static completeRewardByTpNo(token: string, data: Types.CompleteRewardByTpNoData) {
    return get('/api/v1/cs/manage/completeRewardByTpNo', token, data);
  }

  /**
   * CS 상세 페이지 - transactionNo 이용한 매체사 지급 요청
   */
  static completeMediaReward(token: string, data: Types.CompleteMediaRewardData) {
    return get('/api/v1/cs/manage/retryMediaRewardCallbackByTpNo', token, data);
  }

  /**
   * CS 상세 페이지 - transactionNo 이용한 멀티 리워드 지급 요청
   */
  static completeMultiRewardByTpNo(token: string, data: Types.CompleteMultiRewardByTpNoData) {
    return get('/api/v1/cs/manage/CompleteMultiRewardByTpNo', token, data);
  }

  /**
   * CS 상세 페이지 - transactionNo 이용한 매체사 지급 요청
   */
  static completeMediaMultiReward(token: string, data: Types.CompleteMultiMediaRewardData) {
    return get('/api/v1/cs/manage/RetryMediaMultiRewardCallbackByTpNo', token, data);
  }
  /**
   * 대량 CS 처리 페이지 - 전체 멀티 리워드 지급 요청
   */
  static processBulkCS(token: string, data: Types.ProcessBulkCSData) {
    return post<Types.ProcessBulkCSResponseSuccess, Types.ProcessBulkCSData>(
      '/api/v1/cs/manage/processBulkCS',
      data,
      token
    );
  }

  /**
   * 대량 CS 처리 페이지 - 대량 CS 처리 히스토리 조회
   */
  static getBulkCSHistoryList(token: string, params: Types.BulkCSHistoryListParams) {
    return get<Types.BulkCSHistoryListResponseSuccess, Types.BulkCSHistoryListParams>(
      '/api/v1/cs/manage/BulkCSHistory',
      token,
      params
    );
  }

  /**
   * CS 대시보드 페이지 - 그래프/테이블 데이터 조회
   */
  static getCSDashboard(token: string, params: Types.CSDashboardParams) {
    return get<Types.CSDashboardResponseSuccess, Types.CSDashboardParams>(
      '/api/v1/cs/manage/csdashboard',
      token,
      params
    );
  }

  /**
   * CS 대시보드 페이지 - Top 10 CS 조회
   */
  static getTopCSList(token: string, params: Types.TopCSListParams) {
    return get<Types.TopCSListResponseSuccess, Types.TopCSListParams>(
      '/api/v1/cs/manage/csDashboardTopList',
      token,
      params
    );
  }

  /**
   * CS 대시보드 페이지 - Top 캠페인 CS 테이블 조회
   */
  static exportTopCampaignCSTable(token: string, params: Types.ExportTopCampaignCSTableParams) {
    return get<Types.ExportTopCampaignCSTableResponseSuccess, Types.ExportTopCampaignCSTableParams>(
      '/api/v1/cs/manage/campaignExcelExport',
      token,
      params
    );
  }

  /**
   * CS 대시보드 페이지 - Top 매체 CS 테이블 조회
   */
  static exportTopMediaCSTable(token: string, params: Types.ExportTopMediaCSTableParams) {
    return get<Types.ExportTopMediaCSTableResponseSuccess, Types.ExportTopMediaCSTableParams>(
      '/api/v1/cs/manage/mediaExcelExport',
      token,
      params
    );
  }

  /**
   * 참여 정보 관리 페이지 - 캠페인 목록 조회
   */
  static getCSCampaignList(token: string) {
    return get<Types.CSCampaignListResponseSuccess>('/api/v1/cs/manage/csCampaignList', token);
  }

  /**
   * 참여 정보 관리 페이지 - 매체 목록 조회
   */
  static getCSMediaList(token: string) {
    return get<Types.CSMediaListResponseSuccess>('/api/v1/cs/manage/csMediaList', token);
  }

  /**
   * 참여 정보 관리 페이지 - 검색 요청
   */
  static getParticipationInfoList(token: string, params: Types.ParticipationInfoListSearchParams) {
    return get<Types.ParticipationInfoListResponseSuccess, Types.ParticipationInfoListSearchParams>(
      '/api/v1/cs/manage/participationInfoList',
      token,
      params
    );
  }

  /**
   * 참여 정보 삭제
   */
  static removeParticipationInfo(token: string, data: Types.RemoveParticipationData) {
    return post('/api/v1/cs/manage/removeParticipationInfo', data, token);
  }

  /**
   * 참여 이력 삭제 페이지 - 참여이력 확인
   */
  static getQAParticipationList(token: string, params: Types.QAParticipationListParams) {
    return get<Types.QAParticipationListResponseSuccess, Types.QAParticipationListParams>(
      '/api/v1/cs/manage/QAParticipationList',
      token,
      params
    );
  }
  /**
   * 참여 이력 삭제 페이지 - 참여이력 삭제
   */
  static removeQAParticipationList(token: string, data: Types.RemoveQAParticipationListData) {
    return post('/api/v1/cs/manage/RemoveQAParticipationList', data, token);
  }
}
