import { get, post } from 'api/request';

import * as T from './types';

export default class MediaAPI {
  /**
   * 매체 목록 조회
   */
  static getMediaList(token: string) {
    return get<T.MediaListResponseSuccess>('/api/v1/media/manage/MediaList', token);
  }

  /**
   * 매체 iOS 마켓 검수 옵션 업데이트
   */
  static updateMediaMarketReviewStatus(token: string, data: T.UpdateMediaMarketReviewStatusData) {
    return post('/api/v1/media/manage/UpdateMediaMarketReviewStatus', data, token);
  }

  /**
   * 매체 태그 업데이트
   */
  static updateMediaTagMatch(token: string, data: T.UpdateMediaTagMatchData) {
    return post('/api/v1/media/manage/UpdateMediaTagMatch', data, token);
  }

  /**
   * 노출 캠페인 조회
   */
  static getMediaCampaignList(token: string, params: T.MediaCampaignListParams) {
    return get<T.MediaCampaignListResponseSuccess, T.MediaCampaignListParams>(
      '/api/v1/media/manage/MediaCampaignList',
      token,
      params
    );
  }

  /**
   * 노출 캠페인 - 뉴스적립 조회
   */
  static getNewsMediaCampaignList(token: string, params: T.NewsMediaCampaignListParams) {
    return get<T.NewsMediaCampaignListResponseSuccess, T.NewsMediaCampaignListParams>(
      '/api/v1/media/manage/MediaNewsIntegrationInfo',
      token,
      params
    );
  }

  /**
   * 노출 캠페인 - 간편적립 조회
   */
  static getRCPMMediaCampaignList(token: string, params: T.RCPMMediaCampaignListParams) {
    return get<T.RCPMMediaCampaignListResponseSuccess, T.RCPMMediaCampaignListParams>(
      '/api/v1/media/manage/MediaRCPMIntegrationInfo',
      token,
      params
    );
  }

  /**
   * 노출 캠페인 - RV 조회
   */
  static getRVMediaCampaignList(token: string, params: T.RVMediaCampaignListParams) {
    return get<T.RVMediaCampaignListResponseSuccess, T.RVMediaCampaignListParams>(
      '/api/v1/media/manage/MediaRVIntegrationInfo',
      token,
      params
    );
  }

  /**
   * 특정 매체 단가 조회
   */
  static getMediaPriceList(token: string, params: T.MediaPriceListParams) {
    return get<T.MediaPriceListResponseSuccess, T.MediaPriceListParams>(
      '/api/v1/media/manage/MediaPriceList',
      token,
      params
    );
  }

  /**
   * 노출 캠페인 삭제
   */
  static removeMediaCampaignList(token: string, data: T.RemoveMediaCampaignListData) {
    return post('/api/v1/media/manage/RemoveMediaCampaignList', data, token);
  }

  /**
   * 캠페인 목록 조회(검색어)
   */
  static getCampaignListBySearchKey(token: string, params: T.CampaignMetaListBySearchKeyParams) {
    return get<T.CampaignMetaListBySearchKeyResponseSuccess, T.CampaignMetaListBySearchKeyParams>(
      '/api/v1/media/manage/CampaignListBySearchKey',
      token,
      params
    );
  }

  /**
   * 캠페인 목록 조회(태그)
   */
  static getCampaignListByTagNo(token: string, params: T.CampaignMetaListByTagNoParams) {
    return post<T.CampaignMetaListByTagNoResponseSuccess, T.CampaignMetaListByTagNoParams>(
      '/api/v1/media/manage/CampaignListByTagNo',
      params,
      token
    );
  }

  /**
   * 노출 캠페인 추가
   */
  static addMediaCampaign(token: string, data: T.AddMediaCampaignData) {
    return post('/api/v1/media/manage/AddMediaCampaignMatching', data, token);
  }

  /**
   * 노출 캠페인 상태 업데이트
   */
  static updateMediaCampaignStatus(token: string, data: T.UpdateMediaCampaignStatusData) {
    return post('/api/v1/media/manage/UpdateMediaCampaignStatus', data, token);
  }

  /**
   * 노출 캠페인(뉴스적립) 상태 업데이트
   */
  static updateNewsMediaCampaignStatus(token: string, data: T.UpdateNewsMediaCampaignStatusData) {
    return post('/api/v1/media/manage/UpdateMediaNewsStatus', data, token);
  }

  /**
   * 노출 캠페인(간편적립) 상태 업데이트
   */
  static updateRCPMMediaCampaignStatus(token: string, data: T.UpdateRCPMMediaCampaignStatusData) {
    return post('/api/v1/media/manage/UpdateMediaRCPMStatus', data, token);
  }

  /**
   * 노출 캠페인(RV) 상태 업데이트
   */
  static updateRVMediaCampaignStatus(token: string, data: T.UpdateRVMediaCampaignStatusData) {
    return post('/api/v1/media/manage/UpdateMediaRVStatus', data, token);
  }

  /**
   * 노출 캠페인(뉴스적립) 포인트 업데이트
   */
  static updateNewsMediaCampaignPoint(token: string, data: T.UpdateMediaPointData) {
    return post('/api/v1/media/manage/UpdateMediaNewsPoint', data, token);
  }

  /**
   * 노출 캠페인(간편적립) 포인트 업데이트
   */
  static updateRCPMMediaCampaignPoint(token: string, data: T.UpdateMediaPointData) {
    return post('/api/v1/media/manage/UpdateMediaRCPMPoint', data, token);
  }

  /**
   * 노출 캠페인(RV) 포인트 업데이트
   */
  static updateRVMediaCampaignPoint(token: string, data: T.UpdateMediaPointData) {
    return post('/api/v1/media/manage/UpdateMediaRVPoint', data, token);
  }

  /**
   * 노출 캠페인(뉴스적립) rs 업데이트
   */
  static updateNewsMediaCampaignRs(token: string, data: T.UpdateMediaRSData) {
    return post('/api/v1/media/manage/UpdateMediaNewsRS', data, token);
  }

  /**
   * 노출 캠페인(간편적립) rs 업데이트
   */
  static updateRCPMMediaCampaignRs(token: string, data: T.UpdateMediaRSData) {
    return post('/api/v1/media/manage/UpdateMediaRCPMRS', data, token);
  }

  /**
   * 노출 캠페인(RV) rs 업데이트
   */
  static updateRVMediaCampaignRs(token: string, data: T.UpdateMediaRSData) {
    return post('/api/v1/media/manage/UpdateMediaRVRS', data, token);
  }

  /**
   * 매체 표준 단가 업데이트
   */
  static updateStandardMediaPrice(token: string, data: T.UpdateStandardMediaPriceData) {
    return post('/api/v1/media/manage/UpdateStandardPriceList', data, token);
  }

  /**
   * 특정 매체 단가 업데이트
   */
  static updateSpecialMediaPrice(token: string, data: T.UpdateSpecialMediaPriceData) {
    return post('/api/v1/media/manage/UpdateMediaSpecialPriceList', data, token);
  }

  /**
   * 매체 승인 목록 조회
   */
  static getMediaApprovalList(token: string, params: T.MediaApprovalListParams) {
    return get<T.MediaApprovalListResponseSuccess, T.MediaApprovalListParams>(
      '/api/v1/media/manage/MediaApprovalList',
      token,
      params
    );
  }

  /**
   * 매체 승인 상태 업데이트
   */
  static updateMediaApprovalStatus(token: string, data: T.UpdateMediaApprovalStatusData) {
    return post('/api/v1/media/manage/UpdateMediaApprovalStatus', data, token);
  }

  /**
   * 매체 강제 승인 요청
   */
  static forceApproval(token: string, data: T.ForceApprovalData) {
    return post('/api/v1/media/manage/ForceApproval', data, token);
  }

  /**
   * 매체 승인 정보 조회
   */
  static getMediaApprovalInfo(token: string, params: T.MediaApprovalInfoParams) {
    return get<T.MediaApprovalInfoResponseSuccess, T.MediaApprovalInfoParams>(
      '/api/v1/media/manage/MediaApprovalInfo',
      token,
      params
    );
  }

  /**
   * 매체 플랫폼 목록 조회
   */
  static getMediaPlatformList(token: string) {
    return get<T.MediaPlatformListResponseSuccess>('/api/v1/media/manage/MediaPlatformList', token);
  }

  /**
   * 연결 매체 목록 조회
   */
  static getConnectedMediaByPlatform(token: string, params: T.ConnectedMediaByPlatformParams) {
    return get<T.ConnectedMediaByPlatformResponseSuccess, T.ConnectedMediaByPlatformParams>(
      '/api/v1/media/manage/ConnectedMediaPlatform',
      token,
      params
    );
  }

  /**
   * 연결 매체 RS 비율 업데이트
   */
  static updatePlatformRSRate(token: string, data: T.UpdatePlatformRSRateData) {
    return post('/api/v1/media/manage/UpdatePlatformRevenueShare', data, token);
  }

  /**
   * 매체 승인 -> 검수 정보 -> 검증 신청서 데이터 조회
   */
  static getMediaQAEmailForm(token: string, params: T.MediaQAEmailFormParams) {
    return get<T.MediaQAEmailFormResponseSuccess, T.MediaQAEmailFormParams>(
      '/api/v1/media/manage/MediaQAEmailForm',
      token,
      params
    );
  }

  /**
   * 매체 지면 관리 -  지면 리스트 조회 API
   */
  static getBridgePlacementList(token: string) {
    return get<T.BridgePlacementListResponseSuccess>(
      '/api/v1/media/manage/BridgePlacementList',
      token
    );
  }

  /**
   * 매체 지면 관리 - 매체 리스트 조회 API
   */
  static getBridgeMediaList(token: string) {
    return get<T.BridgeMediaListResponseSuccess>('/api/v1/media/manage/BridgeMediaList', token);
  }

  /**
   * 매체 지면 관리 - 상품 리스트 조회 API
   */
  static getBridgeCategoryList(token: string, params: T.BridgeCategoryListParam) {
    return get<T.BridgeCategoryListResponseSuccess>(
      '/api/v1/media/manage/BridgeCategoryList',
      token,
      params
    );
  }

  /**
   * 매체 지면 관리 - 지면 등록(생성) API (Create)
   */
  static createBridgePlacement(token: string, data: T.BridgeInfo) {
    return post('/api/v1/media/manage/CreateBridgePlacement', data, token);
  }

  /**
   * 매체 지면 관리 - 지면 상태관리
   */
  static updateBridgePlacementStatus(token: string, data: T.UpdateBridgePlacementStatusParams) {
    return post('/api/v1/media/manage/UpdateBridgePlacementStatus', data, token);
  }

  /**
   * 매체 지면 관리 - 지면 수정 API (Update)
   */
  static updateBridgePlacement(token: string, data: T.BridgeInfo) {
    return post('/api/v1/media/manage/UpdateBridgePlacement', data, token);
  }

  /**
   * 매체 지면 관리 - 지면 조회 API (Get)
   */
  static getBridgePlacementInfo(token: string, params: T.BridgePlacementParams) {
    return get<T.BridgePlacementResponseSuccess, T.BridgePlacementParams>(
      '/api/v1/media/manage/DetailBridgePlacement',
      token,
      params
    );
  }
}
