import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { UserStatus, useUser } from 'hooks';
import { routes } from 'router';

import { RedirectState } from './redirect-route';

const AuthRoute = () => {
  const { user, isAuthorizedUser, monitoringAndCampaignReportOnly } = useUser();
  const { pathname, search } = useLocation();
  const nextPagePath = `${pathname}${search}`;

  const isMonitoringOrCampaignReportPage =
    pathname.includes('monitoring') || pathname.includes('/report/campaign');

  const redirectState: RedirectState = {
    nextPagePath:
      user.status === UserStatus.LoggedOut
        ? 'dashboard'
        : user.status === UserStatus.Expired
        ? nextPagePath
        : '/',
  };

  if (!isAuthorizedUser) {
    return <Navigate to="/login" replace state={redirectState} />;
  }

  if (monitoringAndCampaignReportOnly && !isMonitoringOrCampaignReportPage) {
    return <Navigate to={routes.monitoring.path} replace state={redirectState} />;
  }

  return <Outlet />;
};

export default AuthRoute;
