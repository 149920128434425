import { ResponseSuccess } from 'api/types';

export enum CSStatus {
  '처리예정' = 0,
  '처리중' = 1,
  '처리완료' = 2,
}

/**
 * CS 관리 페이지 - CS 목록 조회 params
 */
export type CSListParams = {
  startDate: string;
  endDate: string;
  csStatus: CSStatus;
};

export type CS = {
  CSNo: number;
  TransactionNo: number;
  MediaKey: number;
  MediaName: string;
  CampaignKey: string;
  CampaignName: string;
  ThirdpartyID: number;
  ADID: string | null;
  IDFA: string | null;
  USN: string;
  UserName: string;
  MobileNumber: string;
  Email: string;
  Country: string | null;
  OsVer: string | null;
  SdkVer: string | null;
  Model: string | null;
  Status: CSStatus;
  UpdateDateTime: string; // '2022-11-04T15:05:53.38'
  RegistDateTime: string; // '2022-11-04T15:05:53.38'
  CSCount: number;
  Message: string;
  isEncrypted: boolean;
  TID: string;
  isInvalidEmail: boolean; //발송실패여부
};

/**
 * CS 관리 페이지 - CS 목록 조회 결과
 */
export type CSListResponseSuccess = ResponseSuccess & {
  CSList: CS[];
};

/**
 * CS 관리 페이지 - CS 회신 요청 데이터
 */
export type ReplyCSData = {
  csNoList: number[];
  csReplyMessage: string;
};

export type ReplyCSResponseSuccess = ResponseSuccess & {
  ErrorList: number[];
};

/**
 * CS 관리 페이지 - 리워드 지급 요청 데이터
 */
export type CompleteRewardData = {
  csNoList: number[];
};

export type CompleteRewardResponseSuccess = ResponseSuccess & {
  ErrorList: number[];
};

/**
 * CS 상세 페이지 - CS 상세 조회 params
 */
export type CSDetailParams = {
  csNo: number;
};

export type CSMessage = {
  Message: string;
  isAdmin: 0 | 1;
  RegistDateTime: string; // '2022-11-07T09:59:40.463';
  csImageURL: string;
};

export type CSDetail = {
  CSNo: number;
  TransactionNo: number;
  CampaignKey: string;
  CampaignName: string;
  ThirdpartyID: number;
  MediaKey: number;
  MediaName: string;
  USN: string;
  UserName: string;
  MobileNumber: string;
  Email: string;
  Country: string;
  OsVer: string;
  SdkVer: string;
  Model: string;
  Status: CSStatus;
  Check: number;
  isLegacy: boolean;
  GoogleADID: string;
  IOSIDFA: string;
  MessageList: CSMessage[];
  RegistDateTime: string; // '2022-11-07T09:59:40.457'
  TID: string;
};

/**
 * CS 상세 페이지 - CS 상세 조회 결과
 */
export type CSDetailResponseSuccess = ResponseSuccess & {
  CSDetailInfo: CSDetail;
};

/**
 * CS 상세 페이지 -  처리 완료 상태 변경 data
 */
export type UpdateStatusByCsNoData = {
  csNoList: number[];
  csStatus: CSStatus;
};

/**
 * CS 상세 페이지 - 참여 완료 정보 조회 params (해당 매체)
 */
export type ParticipationHistoryListByCurrentMediaParams = {
  otherMedia: false;
  campaignKey: string;
  mediaKey: number;
  usn: string;
};

/**
 * CS 상세 페이지 - 참여 완료 정보 조회 params (다른 매체 - adid)
 */
export type ParticipationHistoryListByOtherMediaParams_ADID = {
  otherMedia: true;
  campaignKey: string;
  adid: string;
};

/**
 * CS 상세 페이지 - 참여 완료 정보 조회 params (다른 매체 - idfa)
 */
export type ParticipationHistoryListByOtherMediaParams_IDFA = {
  otherMedia: true;
  campaignKey: string;
  idfa: string;
};

export type ParticipationHistoryListByOtherMediaParams =
  | ParticipationHistoryListByOtherMediaParams_ADID
  | ParticipationHistoryListByOtherMediaParams_IDFA;

/**
 * CS 상세 페이지 - 참여 완료 정보 조회 params
 */
export type ParticipationHistoryListParams =
  | ParticipationHistoryListByCurrentMediaParams
  | ParticipationHistoryListByOtherMediaParams;

/**
 * 참여 완료 정보
 */
export type ParticipationHistory = {
  rewardQuantity: number;
  campaignMediaCost: number;
  campaignPrice: number;
  transactionParticipationNo: number;
  mediaKey: number;
  mediaName: string;
  campaignKey: string;
  campaignName: string;
  tid: string;
  USN: string;
  ADID: string | null;
  IDFA: string | null;
  registDatetime: string; // '2022-11-05T09:01:05.207'
  completeDatetime: string | null; // '2022-11-05T09:01:05.207'
  rewardDatetime: string | null; // '2022-11-05T09:01:05.207'
  thirdpartyID: number;
  rewardStatus: 0 | 99; // 0: 지급 X / 99: 지급 완료
  isMultiCampaign: boolean;
  multiEventName: string | null;
};

/**
 * CS 상세 페이지 - 참여 완료 정보 조회 결과
 */
export type ParticipationHistoryListResponseSuccess = ResponseSuccess & {
  ParticipationInfoList: ParticipationHistory[];
};

/**
 * CS 상세 페이지 - transactionNo 이용한 리워드 지급 요청 데이터
 */
export type CompleteRewardByTpNoData = {
  transactionNo: ParticipationHistory['transactionParticipationNo'];
  thirdpartyId: ParticipationHistory['thirdpartyID'];
  campaignKey: ParticipationHistory['campaignKey'];
};

/**
 * CS 상세 페이지 - transactionNo 이용한 매체사 지급 요청 데이터
 */
export type CompleteMediaRewardData = {
  transactionNo: ParticipationHistory['transactionParticipationNo'];
  thirdpartyId: ParticipationHistory['thirdpartyID'];
  campaignKey: ParticipationHistory['campaignKey'];
};

/**
 * CS 상세 페이지 - transactionNo 이용한 리워드 지급 요청 데이터
 */
export type CompleteMultiRewardByTpNoData = {
  transactionNo: ParticipationHistory['transactionParticipationNo'];
  multiEventName: ParticipationHistory['multiEventName'];
  campaignKey: ParticipationHistory['campaignKey'];
};

/**
 * CS 상세 페이지 - transactionNo 이용한 매체사 지급 요청 데이터
 */
export type CompleteMultiMediaRewardData = {
  transactionNo: ParticipationHistory['transactionParticipationNo'];
  multiEventName: ParticipationHistory['multiEventName'];
  campaignKey: ParticipationHistory['campaignKey'];
};

export type BulkCS = {
  adid: string;
  idfa: string;
  usn: string;
  tid: string;
  campaignKey: string;
  mediaKey: string;
};

/**
 * 대량 CS 처리 페이지 - 전체 리워드 지급 요청 데이터
 */
export type ProcessBulkCSData = {
  bulkCSList: BulkCS[];
};

export type BulkCSResult = {
  csResult: boolean;
  tid: string;
  adid: string;
  idfa: string;
  usn: string;
  campaignKey: string;
  mediaKey: string;
  registDatetime: string | null;
  completeDatetime: string | null;
  campaignPrice: number;
  campaignMediaCost: number;
  rewardQuantity: number;
  csResultMessage: string;
};

/**
 * 대량 CS 처리 페이지 - 전체 리워드 지급 요청 결과
 */
export type ProcessBulkCSResponseSuccess = ResponseSuccess & {
  BulkCSResultList: BulkCSResult[];
};

/**
 * 대량 CS 처리 페이지 - 대량 CS 처리 히스토리 조회 params
 */
export type BulkCSHistoryListParams = {
  startDate: string;
  endDate: string;
};

export type BulkCSHistory = {
  csResult: boolean;
  tid: string | null;
  adid: string | null;
  idfa: string | null;
  usn: string | null;
  campaignKey: string | null;
  mediaKey: string | null;
  registDatetime: string | null;
  completeDatetime: string | null;
  campaignPrice: number;
  campaignMediaCost: number;
  rewardQuantity: number;
  csResultMessage: string;
  csHistoryRegistDateTime: string;
};

/**
 * 대량 CS 처리 페이지 - 대량 CS 처리 히스토리 조회 결과
 */
export type BulkCSHistoryListResponseSuccess = ResponseSuccess & {
  BulkCSHistoryList: BulkCSHistory[];
};

/**
 * CS 대시보드 페이지 - 그래프/테이블 데이터 조회 params
 */
export type CSDashboardParams = {
  startDate: string;
  endDate: string;
};

export type CSByDate = {
  csDate: string;
  csCount: number;
  csCompletCount: number;
};

/**
 * CS 대시보드 페이지 - 그래프/테이블 데이터 조회 결과
 */
export type CSDashboardResponseSuccess = ResponseSuccess & {
  CSDashboard: CSByDate[];
};

/**
 * CS 대시보드 페이지 - Top 10 CS 조회 params
 */
export type TopCSListParams = CSDashboardParams;

export type TopCampaignCS = {
  CSCount: number;
  CampaignName: string;
  CampaignKey: string;
};

export type TopMediaCS = {
  CSCount: number;
  MediaName: string;
  MediaKey: string;
};

/**
 * CS 대시보드 페이지 - Top 10 CS 조회 결과
 */
export type TopCSListResponseSuccess = ResponseSuccess & {
  TopCSCampaignList: TopCampaignCS[];
  TopCSMediaList: TopMediaCS[];
};

/**
 * CS 대시보드 페이지 - Top 캠페인 CS 전체 테이블 조회 params
 */
export type ExportTopCampaignCSTableParams = CSDashboardParams;

/**
 * CS 대시보드 페이지 - Top 캠페인 CS 전체 테이블 조회 결과
 */
export type ExportTopCampaignCSTableResponseSuccess = string; // text/html

/**
 * CS 대시보드 페이지 - Top 매체 CS 전체 테이블 조회 params
 */
export type ExportTopMediaCSTableParams = CSDashboardParams;

/**
 * CS 대시보드 페이지 - Top 매체 CS 전체 테이블 조회 결과
 */
export type ExportTopMediaCSTableResponseSuccess = string; // text/html

export type CSCampaign = {
  CampaignName: string;
  CampaignKey: string;
  isMultiCampaign: boolean;
};

/**
 * 참여 정보 관리 페이지 - 캠페인 목록 조회 결과
 */
export type CSCampaignListResponseSuccess = ResponseSuccess & {
  CSCampaignList: CSCampaign[];
};

export type CSMedia = {
  MediaName: string;
  MediaKey: string;
};

/**
 * 참여 정보 관리 페이지 - 매체 목록 조회 결과
 */
export type CSMediaListResponseSuccess = ResponseSuccess & {
  CSMediaList: CSMedia[];
};

export enum ParticipationInfoListSearchType {
  USN = 1,
  ADID = 2,
  IDFA = 3,
  TID = 4,
}

/**
 * 참여 정보 관리 페이지 - 검색 요청 params
 * searchType: 1 => searchKey(usn), campaignKey, mediaKey
 * searchType: 2 => searchKey(adid), campaignKey
 * searchType: 3 => searchKey(idfa), campaignKey
 * searchType: 4 => searchKey(tid)
 */
export type ParticipationInfoListSearchParams = {
  searchType: ParticipationInfoListSearchType;
  searchKey: string;
  campaignKey?: string;
  mediaKey?: string;
};

export type ParticipationInfo = {
  rewardQuantity: number;
  campaignMediaCost: number;
  campaignPrice: number;
  transactionParticipationNo: number;
  mediaKey: number;
  mediaName: string;
  campaignKey: string;
  campaignName: string;
  tid: string | null;
  USN: string | null;
  ADID: string | null;
  IDFA: string | null;
  registDatetime: string; // '2022-11-05T09:01:05.207'
  completeDatetime: string | null; // '2022-11-05T09:01:05.207'
  rewardDatetime: string | null; // '2022-11-05T09:01:05.207'
  thirdpartyID: number;
  rewardStatus: 0 | 99; // 0: 지급 X / 99: 지급 완료
  transactionCompleteNo: number;
  isMultiCampaign: boolean;
  multiEventName: string | null;
};

/**
 * 참여 정보 관리 페이지 - 검색 요청 결과
 */
export type ParticipationInfoListResponseSuccess = ResponseSuccess & {
  ParticipationInfoList: ParticipationInfo[];
};

/**
 * 참여 정보 삭제 데이터
 */
export type RemoveParticipationData = {
  participationInfoList: {
    transactionCompleteNo: number;
    campaignKey: string;
    mediaKey: number;
    usn: string;
    adid: string;
    idfa: string;
  }[];
};

/** 참여이력 삭제 페이지 - 참여 이력 확인 params   */
export type QAParticipationListParams = {
  campaignKey: string;
};

export type QAParticipationInfo = {
  transactionParticipationNo: number;
  mediaName: string;
  usn: string;
  adid: string | null;
  idfa: string | null;
  completeDatetime: string;
  rewardDatetime: string;
};

export type QAParticipationListResponseSuccess = ResponseSuccess & {
  QAParticipationList: QAParticipationInfo[];
};

export type RemoveQAParticipationListData = {
  campaignKey: string;
};
