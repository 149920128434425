import * as C from 'api/campaign';
import { ResponseSuccess } from 'api/types';

export type PriorityCampaign = Pick<C.Campaign, 'campaignKey' | 'campaignName'> & {
  priorityNo: number;
  campaignNo: number;
};

/**
 * 우선 순위 캠페인 목록 조회
 */
export type PriorityCampaignListResponseSuccess = ResponseSuccess & {
  AOSPriorityCampaignList: PriorityCampaign[];
  AOSChoicePriorityCampaignList: PriorityCampaign[];
  iOSPriorityCampaignList: PriorityCampaign[];
  iOSChoicePriorityCampaignList: PriorityCampaign[];
};

/**
 * 우선 순위 캠페인 목록 업데이트
 */
export type UpdatePriorityCampaignListData = {
  AOSPriorityCampaignList: PriorityCampaign[] | null;
  AOSChoicePriorityCampaignList: PriorityCampaign[] | null;
  iOSPriorityCampaignList: PriorityCampaign[] | null;
  iOSChoicePriorityCampaignList: PriorityCampaign[] | null;
};

/**
 * 모니터링 목록 조회 타입
 */
export enum MonitoringListStatus {
  라이브 = 1,
  보상 = 2,
  정지 = 3,
  테스트 = 4,
}

export type Monitoring = {
  campaignNo: number;
  campaignKey: string;
  campaignName: string;
  openDateTime: string; // "2022-09-02T00:00:00"
  closeDateTime: string; // "2022-09-02T00:00:00"
  isOpenToUser: boolean;
  isCanComplete: boolean;
  totalGoalCount: number;
  dailyGoalCount: number;
  totalCompleteCount: number;
  dailyCompleteCount: number;
  campaignPrice: number;
  campaignStatus: number;
  participationType: C.ParticipationType;
  tagName: string[];
  priorityNo: number;
  useWhiteAdidFilter: boolean;
};

/**
 * 모니터링 목록 조회 params
 */
export type MonitoringListParams = {
  status: MonitoringListStatus;
};

/**
 * 모니터링 목록 조회 결과
 */
export type MonitoringListResponseSuccess = ResponseSuccess & {
  MonitoringList: Monitoring[];
};

/**
 * 모니터링 상세(캠페인 진행률) 조회 요청 params
 */
export type CampaignTransactionInfoParams = {
  campaignKey: string;
};

/**
 * 모니터링 상세(캠페인 진행률) 조회 결과
 */
export type CampaignTransactionInfoResponseSuccess = ResponseSuccess & {
  CampaignTransactionInfo: {
    campaignNo: number;
    campaignKey: string;
    campaignName: string;
    openDateTime: string; // '2022-05-13T10:28:34';
    closeDateTime: string; // '2022-07-04T23:59:00';
    isOpenToUser: boolean;
    isCanComplete: boolean;
    totalGoalCount: number;
    dailyGoalCount: number;
    totalCompleteCount: number;
    dailyCompleteCount: number;
    campaignPrice: number;
    campaignStatus: C.CampaignStatus;
    participationType: C.ParticipationType;
    tagName: string[];
    priorityNo: number;
    useWhiteAdidFilter: boolean;
  } | null;
};

/**
 * 모니터링 상세(전체, 일 별, 매체 별, 시간 별) 조회 타입
 */
export enum MonitoringType {
  전체 = 1,
  일별 = 2,
  매체별 = 3,
  시간 = 4,
}

/**
 * 모니터링 상세 조회 params
 */
export type MonitoringDetailParams = {
  monitoringType: MonitoringType;
  campaignKey: string;
  startDate: string; // 2022-09-01
  endDate: string;
};

export type AllMonitoring = {
  reportDate: string; // 2022-09-19T00:00:00
  dailyTotalCompleteCount: number;
  monitoringList: {
    dailyCompleteCount: number;
    totalCompleteCount: number;
    mediaKey: number;
    mediaName: string;
  }[];
};

export type DailyMonitoring = {
  reportDate: string; // "2022-09-19T00:00:00"
  mediaKey: number;
  mediaName: string | null;
  dailyCompleteCount: number;
  dailyGoalCount: number;
  totalCompleteCount: number;
  salesUnitPrice: number;
  connectedMediaCount: number;
};

export type MediaMonitoring = DailyMonitoring;

export type HourlyMonitoring = {
  ReportDate: string; // "2022-09-19T00:00:00"
  MediaKey: number;
  MediaName: string | null;
  TimeList: {
    Time: number; // 1 ~ 24
    CompleteQuantity: number;
    Difference: number;
  }[];
};

export type MonitoringListMapper = {
  [MonitoringType.전체]: { AllMonitoringList: AllMonitoring[] };
  [MonitoringType.일별]: { DailyMonitoringList: DailyMonitoring[] };
  [MonitoringType.매체별]: { MediaMonitoringList: MediaMonitoring[] };
  [MonitoringType.시간]: { TimeMonitoringList: HourlyMonitoring[] };
};

/**
 * 모니터링 상세(전체, 일 별, 매체 별, 시간 별 데이터) 조회 결과
 */
export type MonitoringDetailResponseSuccess<T extends MonitoringType> = ResponseSuccess &
  MonitoringListMapper[T];

/**
 * 일 별 모니터링 - 노출 매체 조회 params
 */
export type DailyMediaMonitoringParams = {
  campaignKey: string;
  date: string; // 2022-09-22
};

export type DailyMedia = {
  reportDate: string; // "2022-09-21T00:00:00"
  mediaKey: number;
  mediaName: string;
  dailyCompleteCount: number;
  dailyGoalCount: number;
  totalCompleteCount: number;
  salesUnitPrice: number;
  connectedMediaCount: number;
};

/**
 * 일 별 모니터링 - 노출 매체 조회 결과
 */
export type DailyMediaMonitoringResponseSuccess = ResponseSuccess & {
  DailyMediaList: DailyMedia[];
};

/**
 * 매체 별 모니터링 - 상세보기 조회 params
 */
export type MediaDetailMonitoringListParams = {
  campaignKey: string;
  mediaKey: number;
  startDate: string; // YYYY-MM-DD
  endDate: string; // YYYY-MM-DD
};

/**
 * 매체 별 모니터링 - 상세보기 조회 결과
 */
export type MediaDetailMonitoringListResponseSuccess = ResponseSuccess & {
  DailyMediaList: DailyMedia[];
};

export type HourlyMonitoringChartItem = {
  mediaName: string;
  completeCount: number[]; // maxLength = 24
};

/**
 * 시간 별 모니터링 - 차트 데이터 조회 params
 */
export type HourlyMonitoringChartParams = {
  campaignKey: string;
  date: string; // YYYY-MM-DD
};

/**
 * 시간 별 모니터링 - 차트 데이터 조회 결과
 */
export type HourlyMonitoringChartResponseSuccess = ResponseSuccess & {
  MonitoringChartList: HourlyMonitoringChartItem[];
};

export enum TargetGroupSchema {
  ADID = 1,
  IDFA = 2,
}

export type CommonTargetGroup = {
  targetName: string;
  group_id: string;
  schema: TargetGroupSchema;
  totalCount: number;
  progressCount: number;
  connectedCampaignCount: number;
  updateDateTime: string;
  registDateTime: string;

  // 사용 X
  DEFAULT_S3_PATH: string;
  campaignKey: string;
  s3Url: string;
  isTargetCampaign: null;
};

/**
 * 타겟팅 관리 리스트 조회
 */
export type CommonTargetGroupListResponseSuccess = ResponseSuccess & {
  TargetGroupList: CommonTargetGroup[];
};

/**
 * 타겟 그룹 이름 업데이트
 */
export type UpdateTargetGroupNameData = {
  targetGroupId: string;
  targetGroupName: string;
};

/**
 * 타겝 그룹 등록
 */
export type CreateTargetGroupData = FormData;

/**
 * 타겟 추가 - 기존 타겟 그룹에 단일 adid or idfa 추가
 */
export type UpdateTargetUserIdentifierData = {
  campaignKey: string;
  targetGroupId: string;
  identifier: string;
  schema: TargetGroupSchema;
};

/**
 * 타겟 추가 - 기존 타겟 그룹에 파일 추가
 * campaignKey : 0 (공통 타겟은 0으로 보내주어야 함)
 * targetGroupId
 * targetGroupName
 * uploadFile
 * schema
 */
export type UpdateTargetUserFileData = FormData;

export type TargetGroupDetailInfoParams = {
  targetGroupId: string;
};

export enum TargetGroupType {
  '알 수 없음' = 0, // 신규 운영툴에서 테스트로 생성한 그룹 및 캠페인에 매칭된 것으로 migration 불가
  타겟 = 1,
  디타겟 = 2,
  '포스트백 디타겟' = 3,
  '기참여자 디타겟' = 4,
}

export type ConnectedCampaign = {
  campaignName: string;
  campaignKey: string;
  platform: C.PlatformType;
  campaignStatus: C.CampaignStatus;
  targetGroupType: TargetGroupType;
};

/**
 * 타겟팅 관리 -> 연결 캠페인 목록 조회
 */
export type TargetGroupDetailInfoResponseSuccess = ResponseSuccess & {
  TargetGroupDetailInfo: ConnectedCampaign[];
};

/**
 * 캠페인 태그 조회 (운영)
 */
export type O_CampaignTag = C.CampaignTag & {
  connectedCampaignCount: number;
};

export type O_CampaignTagListResponseSuccess = ResponseSuccess & {
  CampaignTagList: O_CampaignTag[];
};

/**
 * 캠페인 태그 생성
 */
export type CreateCampaignTagData = {
  campaignCategoryTagNo: number;
  campaignCategoryTagName: string;
  tagName: string;
};

/**
 * 캠페인 태그 삭제
 */
export type DeleteCampaignTagData = {
  campaignTagNoList: number[];
};

/**
 * 캠페인 태그 - 연결 캠페인 조회
 */
export type ConnectedCampaignListParams = {
  campaignTagNo: number;
};

export type ConnectedCampaignListResponseSuccess = ResponseSuccess & {
  ConnectedCampaignList: {
    campaignName: string;
    campaignKey: string;
  }[];
};

/**
 * 연결 캠페인 삭제
 */
export type DeleteCampaignTagMatchInfoData = {
  campaignKey: string;
  campaignTagNo: number;
};

/**
 * 매체 태그 조회 (운영)
 */
export type O_MediaTag = C.MediaTag & {
  connectedMediaCount: number;
};

export type O_MediaTagListResponseSuccess = ResponseSuccess & {
  MediaTagList: O_MediaTag[];
};

/**
 * 매체 태그 생성
 */
export type CreateMediaTagData = {
  mediaCategoryTagNo: number;
  mediaCategoryTagName: string;
  tagName: string;
};

/**
 * 매체 태그 삭제
 */
export type DeleteMediaTagData = {
  mediaTagNoList: number[];
};

/**
 * 매체 태그 - 연결 매체 조회
 */
export type ConnectedMediaListParams = {
  mediaTagNo: number;
};

export type ConnectedMediaListResponseSuccess = ResponseSuccess & {
  ConnectedMediaList: {
    mediaName: string;
    mediaKey: number;
  }[];
};

/**
 * 연결 매체 삭제
 */
export type DeleteMediaTagMatchInfoData = {
  mediaKey: number;
  mediaTagNo: number;
};

/**
 * 뉴스적립 네트워크
 */
export type NewsNetwork = {
  networkName: string;
  integrationTypeNo: number;
  displayPriority: number;
  costPerClick: number;
  integratedMediaCount: number;
};

/**
 * 뉴스적립 네트워크 목록 조회
 */
export type NewsNetworkListResponseSuccess = ResponseSuccess & {
  NewsNetworkList: NewsNetwork[];
};

/**
 * 뉴스적립 네트워크 - 연동 매체 목록 조회
 */
export type IntegratedNewsMediaListParams = {
  integrationTypeNo: number;
};

/**
 * 뉴스적립 네트워크 - 연동 매체
 */
export type IntegratedNewsMedia = {
  mediaKey: number;
  mediaName: string;
  status: number;
  rsRate: number;
  newsPoint: number;
};

/**
 * 뉴스적립 네트워크 - 연동 매체 목록 조회
 */
export type IntegratedNewsMediaListResponseSuccess = ResponseSuccess & {
  IntegratedNewsMediaList: IntegratedNewsMedia[];
};

/**
 * 뉴스적립 - 연동 매체 추가 및 상태 업데이트 데이터
 */
export type UpdateMediaNewsIntegrationStatusData = {
  mediaKeyList: number[];
  integrationTypeNoList: number[];
  status: boolean;
};

export type AvailableNewsMedia = {
  mediaKey: number;
  mediaName: string;
  point: number;
};

/**
 * 뉴스적립 네트워크 - 연동 가능한 매체 목록 조회
 */
export type AvailableNewsMediaListResponseSuccess = ResponseSuccess & {
  AvailableNewsMediaList: AvailableNewsMedia[];
};

/**
 * 간편적립 네트워크
 */

export type RCPMNetwork = {
  networkName: string;
  integrationTypeNo: number;
  displayPriority: number;
  integratedMediaCount: number;
};

/**
 * 간편적립 네트워크 목록 조회
 */
export type RCPMNetworkListResponseSuccess = ResponseSuccess & {
  RCPMNetworkList: RCPMNetwork[];
};

export type AvailableRCPMMedia = {
  mediaKey: number;
  mediaName: string;
  point: number;
};

/**
 * 간편적립 네트워크 - 연동 가능한 매체 목록 조회
 */
export type AvailableRCPMMediaListResponseSuccess = ResponseSuccess & {
  AvailableRCPMMediaList: AvailableRCPMMedia[];
};

export type IntegratedRCPMMedia = {
  mediaKey: number;
  mediaName: string;
  status: number;
  rsRate: number;
  rcpmPoint: number;
};

/**
 * 간편적립 네트워크 - 연동 매체 목록 조회
 */
export type IntegratedRCPMMediaListParams = {
  integrationTypeNo: number;
};

/**
 * 간편적립 네트워크 - 연동 매체 목록 조회
 */
export type IntegratedRCPMMediaListResponseSuccess = ResponseSuccess & {
  IntegratedRCPMMediaList: IntegratedRCPMMedia[];
};

/**
 * 간편적립 - 연동 매체 추가 및 상태 업데이트 데이터
 */
export type UpdateMediaRCPMIntegrationStatusData = {
  mediaKeyList: number[];
  integrationTypeNoList: number[];
  status: boolean;
};

/**
 * 웹툰적립 네트워크
 */

export type WebtoonNetwork = {
  networkName: string;
  integrationTypeNo: number;
  displayPriority: number;
  costPerClick: number;
  integratedMediaCount: number;
};

/**
 * 웹툰적립 네트워크 목록 조회
 */
export type WebtoonNetworkListResponseSuccess = ResponseSuccess & {
  WebtoonNetworkList: WebtoonNetwork[];
};

export type AvailableWebtoonMedia = {
  mediaKey: number;
  mediaName: string;
  point: number;
};

/**
 * 웹툰적립 네트워크 - 연동 가능한 매체 목록 조회
 */
export type AvailableWebtoonMediaListResponseSuccess = ResponseSuccess & {
  AvailableWebtoonMediaList: AvailableWebtoonMedia[];
};

export type IntegratedWebtoonMedia = {
  mediaKey: number;
  mediaName: string;
  status: number;
  rsRate: number;
  webtoonPoint: number;
};

/**
 * 웹툰적립 네트워크 - 연동 매체 목록 조회
 */
export type IntegratedWebtoonMediaListParams = {
  integrationTypeNo: number;
};

/**
 * 웹툰적립 네트워크 - 연동 매체 목록 조회
 */
export type IntegratedWebtoonMediaListResponseSuccess = ResponseSuccess & {
  IntegratedNewsMediaList: IntegratedWebtoonMedia[];
};

/**
 * 웹툰적립 - 연동 매체 추가 및 상태 업데이트 데이터
 */
export type UpdateMediaWebtoonIntegrationStatusData = {
  mediaKeyList: number[];
  integrationTypeNoList: number[];
  status: boolean;
};

/**
 * RV 네트워크
 */

export type RVNetwork = {
  networkName: string;
  integrationTypeNo: number;
  displayPriority: number;
  // costPerClick: number;
  integratedMediaCount: number;
};

/**
 * RV 네트워크 목록 조회
 */
export type RVNetworkListResponseSuccess = ResponseSuccess & {
  RVNetworkList: RVNetwork[];
};

export type AvailableRVMedia = {
  mediaKey: number;
  mediaName: string;
  point: number;
};

/**
 * RV 네트워크 - 연동 가능한 매체 목록 조회
 */
export type AvailableRVMediaListResponseSuccess = ResponseSuccess & {
  AvailableRVMediaList: AvailableRVMedia[];
};

export type IntegratedRVMedia = {
  mediaKey: number;
  mediaName: string;
  status: number;
  rsRate: number;
  rvPoint: number;
};

/**
 * RV 네트워크 - 연동 매체 목록 조회
 */
export type IntegratedRVMediaListParams = {
  integrationTypeNo: number;
};

/**
 * RV 네트워크 - 연동 매체 목록 조회
 */
export type IntegratedRVMediaListResponseSuccess = ResponseSuccess & {
  IntegratedRVMediaList: IntegratedRVMedia[];
};

/**
 * RV - 연동 매체 추가 및 상태 업데이트 데이터
 */
export type UpdateMediaRVIntegrationStatusData = {
  mediaKeyList: number[];
  integrationTypeNoList: number[];
  status: boolean;
};

/**
 * white Adid 조회 params
 * */
export type CheckWhiteAdidParams = {
  adid: string;
};

/**
 * white Adid 조회 성공
 * */
export type CheckWhiteAdidResponseSuccess = ResponseSuccess & {
  IsWhiteAdid: boolean;
};

/**
 * white Adid 등록
 */
export type AddWhiteAdidData = {
  adid: string;
};

export type GameRanking = {
  gameTitle: string;
  ranking: number;
  rankDifference: number;
  isNew: boolean;
};

export type GameRankingList = {
  reportDateTime: string;
  hour: number;
  gameRankingList: GameRanking[];
};

export type GetGameRankingListSuccess = ResponseSuccess & {
  GameRankList: GameRankingList[];
};

export type GetGameRankingParams = {
  startDate: string; // 2022-09-01
  endDate: string;
};

export type DetailGameRanking = {
  reportDateTime: string;
  hour: number;
  gameTitle: string;
  ranking: number;
  rankDifference: number;
  isNew: boolean;
};

export type GetDetailGameRankingListParams = GetGameRankingParams & {
  gameTitle: string;
};

export type GetDetailGameRankingListSuccess = ResponseSuccess & {
  DetailGameRanking: DetailGameRanking[];
};

export type GetDetailGameTitleListParams = {
  searchKey: string;
};

export type GetDetailGameTitleListSuccess = ResponseSuccess & {
  SearchResult: string[];
};
