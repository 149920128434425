import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';

import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { Header, HeaderGroup, Row } from '@tanstack/react-table';
import { debounce, isFunction, isNumber } from 'lodash-es';

import { fullHeight, fullWidth, overflow, SSO } from 'styles';
import { AnyObject } from 'utils';

import { DEFAULT_ROW_HEIGHT } from './const';
import Pagination from './pagination';
import TableBody from './table-body';
import TableFooter from './table-footer';
import TableHead from './table-head';

export type TableProps<T extends AnyObject> = {
  tableId: string;
  rows: Row<T>[]; // paginatedRows(mostly) | filteredRows
  headers: Header<T, unknown>[]; // leaf headers
  footerGroups?: HeaderGroup<T>[];
  headerGroups?: Header<T, unknown>[]; // leaf headers
  rowHeight?: number;
  loading?: boolean;
  totalSize: number; // sum of columns' width
  stickyColumnsCount?: number; // number of sticky columns when table width overflows (from left)
  pageCount?: number;
  onChangePage?: (page: number) => void;
  pageIndex?: number;
  pageSize?: number;
  totalRowsCount?: number;
  draggable?: boolean;
  onDragEnd?: (result: DropResult) => void;
  noRowsText?: string;
};

const tableContainerStyle: SSO = {
  flex: '1 1 0',
  border: 1,
  borderColor: ({ palette }) => (palette.mode === 'light' ? 'line.grey' : 'line.darkGrey'),
};

const Table = <T extends AnyObject>(props: TableProps<T>) => {
  const {
    headerGroups,
    tableId,
    rows,
    headers,
    footerGroups,
    rowHeight = DEFAULT_ROW_HEIGHT,
    loading = false,
    totalSize,
    stickyColumnsCount = 1,
    pageCount,
    onChangePage,
    pageIndex,
    pageSize,
    totalRowsCount,
    draggable = false,
    onDragEnd,
    noRowsText = '조회 결과가 없습니다.',
  } = props;

  const [tableWidth, setTableWidth] = useState(totalSize);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  /**
   * 테이블 가로 스크롤 여부
   */
  const enableScrollX = totalSize > tableWidth;

  /**
   * 각 컬럼의 너비 목록
   */
  const columnWidthList = headers.map((header) => header.getSize());

  /**
   * 각 컬럼의 x 좌표 목록 (가로 스크롤 발생 시)
   */
  const stickyColumnsPositionList = useMemo(() => {
    let left = 0;
    const result = [left];

    for (let i = 1; i < columnWidthList.length; i++) {
      left += columnWidthList[i - 1];
      result.push(left);
    }

    return result;
  }, [columnWidthList]);

  const handleChangePage = useMemo(
    () =>
      isFunction(onChangePage)
        ? (e: ChangeEvent<unknown>, page: number) => {
            onChangePage(page - 1);
          }
        : null,
    [onChangePage]
  );

  /**
   * resize 이벤트 발생 시 테이블 너비 업데이트
   */
  useEffect(() => {
    const handleSetTableHeight = debounce(() => {
      const tableEl = tableRef.current;

      if (tableEl) {
        setTableWidth(tableEl.offsetWidth);
      }
    }, 300);

    handleSetTableHeight();

    window.addEventListener('resize', handleSetTableHeight);

    return () => {
      window.removeEventListener('resize', handleSetTableHeight);
    };
  }, []);

  const placeholder = useMemo(
    () => (
      <Stack role="rowgroup" sx={{ flex: '1 1 0', position: 'relative' }}>
        <Stack role="row" direction="row" sx={{ height: 1 }}>
          <Box
            role="cell"
            sx={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 1,
              height: 1,
              border: 'none',
            }}
          >
            {loading ? <CircularProgress /> : <Typography>{noRowsText}</Typography>}
          </Box>
        </Stack>
      </Stack>
    ),
    [loading, noRowsText]
  );

  const dataLoaded = useMemo(() => !loading && rows.length > 0, [rows.length, loading]);

  const usePagination =
    isNumber(pageCount) &&
    isNumber(pageIndex) &&
    isNumber(pageSize) &&
    isNumber(totalRowsCount) &&
    isFunction(handleChangePage);

  return (
    <Stack
      spacing={3}
      sx={[
        fullWidth,
        fullHeight,
        {
          overflow: 'hidden',
          bgcolor: 'background.paper',
        },
      ]}
    >
      <Stack id={tableId} ref={tableContainerRef} sx={[fullWidth, overflow, tableContainerStyle]}>
        <Stack
          role="table"
          ref={tableRef}
          sx={[
            { minWidth: 1, width: enableScrollX ? 'max-content' : 1 },
            !dataLoaded && fullHeight,
          ]}
        >
          {dataLoaded ? (
            <>
              <TableHead
                headerGroups={headerGroups}
                headers={headers}
                stickyColumnsCount={stickyColumnsCount}
                stickyColumnsPositionList={stickyColumnsPositionList}
                enableScrollX={enableScrollX}
                rowHeight={rowHeight}
              />
              <TableBody
                containerRef={tableContainerRef}
                rows={rows}
                rowHeight={rowHeight}
                stickyColumnsCount={stickyColumnsCount}
                stickyColumnsPositionList={stickyColumnsPositionList}
                enableScrollX={enableScrollX}
                draggable={draggable}
                onDragEnd={onDragEnd}
              />
              {footerGroups && (
                <TableFooter
                  footerGroups={footerGroups}
                  rowHeight={rowHeight}
                  stickyColumnsCount={stickyColumnsCount}
                  stickyColumnsPositionList={stickyColumnsPositionList}
                  enableScrollX={enableScrollX}
                />
              )}
            </>
          ) : (
            placeholder
          )}
        </Stack>
      </Stack>
      {usePagination && (
        <Pagination
          count={pageCount > 0 ? pageCount : 1}
          showFirstButton
          showLastButton
          onChange={handleChangePage}
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRowsCount={totalRowsCount}
        />
      )}
    </Stack>
  );
};

export default Table;
