import { ResponseSuccess } from 'api/types';

export enum CompanyCategoryFlag {
  PM = 1,
  랩사 = 2,
  대행사 = 4,
  광고주 = 8,
}

// DUPLICATED TYPE ALIAS (Company)
export type Group = {
  CompanyNo: number;
  CompanyKey: number;
  CompanyName: string;
  IsCompany: boolean;
  CompanyCategoryFlag: number; // 1: PM, 2: 랩사, 4: 대행사, 8: 광고주, 16: 매체 (비트연산 - ex. 랩사 | 대행사 = 6)
  CompanyRegistImg: string | null; // relative path
  BankbookImg: string | null; // relative path
  CompanyRegistNo: string;
  CompanyAddress: string;
  CompanyContactNo: string;
  CompanyFaxNo: string;
  BankName: string | null;
  BankingAccount: string | null;
  AccountHolder: string | null;
  CompanyMemeberCount: number;
  CompanyMemberList: (string | null)[];
  RegistDatetime: string; // "2014-12-01T15:55:03.017"
  ERPNo: null;
  ERPName: null;
};

export type AllCompanyListResponseSuccess = ResponseSuccess & {
  CompanyList: Group[];
};

export type AllCompanyMemberListResponseSuccess = ResponseSuccess &
  Pick<Group, 'CompanyMemberList'>;

export type AllCompanyMemberListParams = Pick<Group, 'CompanyNo'>;

export type CreateCompanyData = Pick<
  Group,
  | 'CompanyName'
  | 'IsCompany'
  | 'CompanyCategoryFlag'
  | 'CompanyRegistImg'
  | 'BankbookImg'
  | 'CompanyRegistNo'
  | 'CompanyAddress'
  | 'CompanyContactNo'
  | 'CompanyFaxNo'
  | 'BankName'
  | 'BankingAccount'
  | 'AccountHolder'
>;

export type CreateCompanyResponseSuccess = ResponseSuccess & {
  CreatedCompany: Group;
};

export type UpdateCompanyData = Pick<Group, 'CompanyNo' | 'CompanyKey'> & CreateCompanyData;

export enum MemberRole {
  '어드민 로그인 및 조회' = 35,
  '매체 승인 메뉴 접근' = 36,
  '태그 수정 및 삭제 가능' = 37,
  '파트너스 페이지 어드민' = 38,
  '모니터링 및 캠페인 리포트만 접근 가능' = 39,
}

// DUPLICATED TYPE ALIAS (Customer)
export type Member = {
  CustomerNo: number;
  RegistDatetime: number; // UNIX TIMESTAMP

  CustomerName: string;
  Email: string | null;
  Password: string | null;

  // 소속 업체
  CompanyName: string | null;
  CompanyKey: number;
  CompanyNo: number;
  IsCompany: boolean;

  DepartmentName: string | null; // 부서/직급
  CustomerCompanyPhoneNo: string | null; // 연락처 (유선)
  CustomerPhoneNo: string | null; // 연락처 (모바일)
  Role: number[] | null; // CustomerRole 외 기존 Role 포함될 가능성 있음
};

export type AllCustomerListResponseSuccess = ResponseSuccess & {
  CustomerList: Member[];
};

export type CreateCustomerData = Pick<
  Member,
  | 'CustomerName'
  | 'Email'
  | 'Password'
  | 'CompanyName'
  | 'CompanyKey'
  | 'CompanyNo'
  | 'IsCompany'
  | 'DepartmentName'
  | 'CustomerCompanyPhoneNo'
  | 'CustomerPhoneNo'
  | 'Role'
>;

export type CreateCustomerResponseSuccess = ResponseSuccess & {
  CreatedCustomer: Member;
};

export type UpdateCustomerData = Pick<
  Member,
  | 'CustomerNo'
  | 'CustomerName'
  | 'Email'
  | 'CompanyName'
  | 'CompanyKey'
  | 'CompanyNo'
  | 'IsCompany'
  | 'DepartmentName'
  | 'CustomerCompanyPhoneNo'
  | 'CustomerPhoneNo'
  | 'Role'
>;

export enum FAQStatus {
  ON = 1,
  OFF = 0,
}

export type DeleteCustomerData = {
  customerIDList: Member['Email'][];
};

export type FAQData = {
  faqNo: number;
  title: string;
  link: string;
  status: FAQStatus;
  priority: number;
  updateDateTime: string;
};

export type GetFAQListResponseSuccess = ResponseSuccess & { FAQList: FAQData[] };

export type AddFAQData = {
  title: string;
  link: string;
};

export type UpdateFAQData = {
  faqNo: number;
  title?: string;
  link?: string;
  status?: FAQStatus;
};

export type UpdateFAQPriorityData = {
  faqNoList: number[];
};
