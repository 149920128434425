import { ComponentType, lazy, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { Backdrop, CircularProgress } from '@mui/material';

import NotFound from 'pages/not-found';

import AuthRoute from './auth-route';
import { routes } from './const';
import LayoutRoute from './layout-route';
import RedirectRoute from './redirect-route';

// 대시보드 페이지
const Dashboard = lazy(() => import('pages/dashboard'));

// 캠페인 메뉴 페이지
const CampaignManagement = lazy(() => import('pages/campaign-management'));
const CampaignMediaManagement = lazy(() => import('pages/campaign-media-management'));
const UpdateCampaignDetail = lazy(() => import('pages/update-campaign-detail'));
const ExternalCampaign = lazy(() => import('pages/external-campaign'));
const ShoppingCampaign = lazy(() => import('pages/shopping-campaign'));
const RemovedCampaign = lazy(() => import('pages/removed-campaign'));
const CampaignStatus = lazy(() => import('pages/campaign-status'));
const CampaignStatusDetail = lazy(() => import('pages/campaign-status-detail'));

// 매체 메뉴 페이지
const MediaManagement = lazy(() => import('pages/media-management'));
const MediaCampaignManagement = lazy(() => import('pages/media-campaign-management'));
const MediaApproval = lazy(() => import('pages/media-approval'));
const MediaApprovalDetail = lazy(() => import('pages/media-approval-detail'));
const MediaPriceManagement = lazy(() => import('pages/media-price-management'));
const MediaPlatformManagement = lazy(() => import('pages/media-platform-management'));
const MediaPlacementManagement = lazy(() => import('pages/media-placement-management'));
const CreateMediaPlacement = lazy(
  () => import('pages/create-media-placement/create-media-placement')
);
const UpdateMediaPlacement = lazy(
  () => import('pages/create-media-placement/update-media-placement')
);

// 운영 메뉴 페이지
const Monitoring = lazy(() => import('pages/monitoring'));
const MonitoringDetail = lazy(() => import('pages/monitoring-detail'));
const PriorityManagement = lazy(() => import('pages/priority-management'));
const TargetingManagement = lazy(() => import('pages/targeting-management'));
const TagManagement = lazy(() => import('pages/tag-management'));
const NewsWebtoonRcpmManagement = lazy(() => import('pages/news-webtoon-rcpm-management'));
const WhiteAdidManagement = lazy(() => import('pages/white-adid-management'));
const GameRanking = lazy(() => import('pages/game-ranking'));

// 리포트
const SalesReport = lazy(() => import('pages/sales-report'));
const CampaignReport = lazy(() => import('pages/campaign-report'));
const CampaignReportDetail = lazy(() => import('pages/campaign-report-detail'));
const MediaReport = lazy(() => import('pages/media-report'));
const MediaReportDetail = lazy(() => import('pages/media-report-detail'));

// 정산
const CampaignSettlement = lazy(() => import('pages/campaign-settlement'));
const CompanySettlement = lazy(() => import('pages/company-settlement'));
const CompanySettlementDetail = lazy(() => import('pages/company-settlement-detail'));
const PaymentRequestDetail = lazy(() => import('pages/payment-request-detail'));
const CompanyRevenueDetail = lazy(() => import('pages/company-revenue-detail'));

// CS
const CSManagement = lazy(() => import('pages/cs-management'));
const CSDetail = lazy(() => import('pages/cs-detail'));
const BulkCSManagement = lazy(() => import('pages/bulk-cs-management'));
const CSDashboard = lazy(() => import('pages/cs-dashboard'));
const CSParticipationManagement = lazy(() => import('pages/cs-participation-management'));
const QAParticipationCancellation = lazy(() => import('pages/qa-participation-cancellation'));

// 관리 메뉴 페이지
const CompanyAndCustomer = lazy(() => import('pages/company-and-customer'));
const FaqContent = lazy(() => import('pages/faq-content'));

// 로그
const SystemLog = lazy(() => import('pages/system-log'));
const CampaignLog = lazy(() => import('pages/campaign-log'));

// 로그인 페이지
const Login = lazy(() => import('pages/login'));

function withSuspense(WrappedComponent: ComponentType) {
  const SuspensedComponent = () => {
    return (
      <Suspense
        fallback={
          <Backdrop
            open
            sx={{ color: 'text.highlighted', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
      >
        <WrappedComponent />
      </Suspense>
    );
  };

  return <SuspensedComponent />;
}

const AppRouter = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<AuthRoute />}>
        <Route element={<LayoutRoute />}>
          {/* 대시보드 */}
          <Route path={routes.dashboard.path} element={withSuspense(Dashboard)} />
          {/* 캠페인 관리 */}
          <Route
            path={routes.campaign.path}
            element={<Navigate to={routes.campaignManagement.path} replace />}
          />
          <Route path={routes.campaignManagement.path} element={withSuspense(CampaignManagement)} />
          {/* 노출 매체 관리 */}
          <Route
            path={routes.campaignMediaManagement.path}
            element={withSuspense(CampaignMediaManagement)}
          />
          {/* 캠페인 상세 업데이트 */}
          <Route
            path={routes.updateCampaignDetail.path}
            element={withSuspense(UpdateCampaignDetail)}
          />
          {/* 타네트워크 캠페인 */}
          <Route path={routes.externalCampaign.path} element={withSuspense(ExternalCampaign)} />
          {/* 쇼핑 네트워크 캠페인 */}
          <Route path={routes.shoppingCampaign.path} element={withSuspense(ShoppingCampaign)} />
          {/* 삭제 캠페인 */}
          <Route path={routes.removedCampaign.path} element={withSuspense(RemovedCampaign)} />
          {/* 캠페인 변경 사항 관리*/}
          <Route path={routes.campaignStatus.path} element={withSuspense(CampaignStatus)} />
          {/* 캠페인 변경 사항 관리*/}
          <Route
            path={routes.campaignStatusManagement.path}
            element={withSuspense(CampaignStatusDetail)}
          />
          {/* 매체 관리 */}
          <Route
            path={routes.media.path}
            element={<Navigate to={routes.mediaManagement.path} replace />}
          />
          <Route path={routes.mediaManagement.path} element={withSuspense(MediaManagement)} />
          {/* 노출 캠페인 관리 */}
          <Route
            path={routes.mediaCampaignManagement.path}
            element={withSuspense(MediaCampaignManagement)}
          />
          {/* 매체 승인 */}
          <Route path={routes.mediaApproval.path} element={withSuspense(MediaApproval)} />
          {/* 매체 승인 -> 검수 정보 */}
          <Route
            path={routes.mediaApprovalDetail.path}
            element={withSuspense(MediaApprovalDetail)}
          />
          {/* 매체 단가 관리 */}
          <Route path={routes.mediaPriceManagement.path}>
            <Route index element={withSuspense(MediaPriceManagement)} />
            <Route path=":mediaKey" element={withSuspense(MediaPriceManagement)} />
          </Route>
          {/*매체 지면 관리 */}
          <Route path={routes.mediaPlacement.path}>
            <Route index element={withSuspense(MediaPlacementManagement)} />
            <Route path=":bridgePlacementId" element={withSuspense(UpdateMediaPlacement)} />
            {/* <Route path=":bridgePlacementId" element={withSuspense)} /> */}
          </Route>

          <Route
            path={routes.createMediaPlacement.path}
            element={withSuspense(CreateMediaPlacement)}
          />

          {/* 플랫폼 관리 */}
          <Route
            path={routes.mediaPlatformManagement.path}
            element={withSuspense(MediaPlatformManagement)}
          />
          {/* 모니터링 */}
          <Route
            path={routes.operation.path}
            element={<Navigate to={routes.monitoring.path} replace />}
          />
          <Route path={routes.monitoring.path} element={withSuspense(Monitoring)} />
          {/* 모니터링 상세 */}
          <Route path={routes.monitoringDetail.path} element={withSuspense(MonitoringDetail)} />
          {/* 우선순위 관리 */}
          <Route path={routes.priorityManagement.path} element={withSuspense(PriorityManagement)} />
          {/* 타겟팅 관리 */}
          <Route
            path={routes.targetingManagement.path}
            element={withSuspense(TargetingManagement)}
          />
          {/* 태그 관리 */}
          <Route path={routes.tagManagement.path} element={withSuspense(TagManagement)} />
          {/* 뉴스/간편적립 관리 */}
          <Route
            path={routes.newsWebtoonRcpmManagement.path}
            element={withSuspense(NewsWebtoonRcpmManagement)}
          />
          <Route
            path={routes.whiteAdidManagement.path}
            element={withSuspense(WhiteAdidManagement)}
          />
          <Route path={routes.gameRangking.path} element={withSuspense(GameRanking)} />
          {/* 매출 리포트 */}
          <Route
            path={routes.report.path}
            element={<Navigate to={routes.salesReport.path} replace />}
          />
          <Route path={routes.salesReport.path} element={withSuspense(SalesReport)} />
          {/* 캠페인 리포트 */}
          <Route path={routes.campaignReport.path} element={withSuspense(CampaignReport)} />
          <Route
            path={routes.campaignReportDetail.path}
            element={withSuspense(CampaignReportDetail)}
          />
          <Route path={routes.mediaReport.path} element={withSuspense(MediaReport)} />
          <Route path={routes.mediaReportDetail.path} element={withSuspense(MediaReportDetail)} />
          {/* 정산 */}
          <Route
            path={routes.settlement.path}
            element={<Navigate to={routes.campaignSettlement.path} replace />}
          />
          <Route path={routes.campaignSettlement.path} element={withSuspense(CampaignSettlement)} />
          <Route path={routes.companySettlement.path} element={withSuspense(CompanySettlement)} />
          <Route
            path={routes.companySettlementDetail.path}
            element={withSuspense(CompanySettlementDetail)}
          />
          <Route
            path={routes.paymentRequestDetail.path}
            element={withSuspense(PaymentRequestDetail)}
          />
          <Route
            path={routes.companyRevenueDetail.path}
            element={withSuspense(CompanyRevenueDetail)}
          />
          {/* CS 관리 */}
          <Route
            path={routes.cs.path}
            element={<Navigate to={routes.csManagement.path} replace />}
          />
          <Route path={routes.csManagement.path} element={withSuspense(CSManagement)} />
          <Route path={routes.csDetail.path} element={withSuspense(CSDetail)} />
          <Route path={routes.bulkCSManagement.path} element={withSuspense(BulkCSManagement)} />
          <Route path={routes.csDashboard.path} element={withSuspense(CSDashboard)} />
          <Route
            path={routes.csParticipationManagement.path}
            element={withSuspense(CSParticipationManagement)}
          />
          <Route
            path={routes.qaParticipationCancellation.path}
            element={withSuspense(QAParticipationCancellation)}
          />
          {/* 업체 및 회원 관리 */}
          <Route
            path={routes.management.path}
            element={<Navigate to={routes.companyAndCustomer.path} replace />}
          />
          <Route path={routes.companyAndCustomer.path} element={withSuspense(CompanyAndCustomer)} />
          <Route path={routes.faqContent.path} element={withSuspense(FaqContent)} />
          {/* 로그 */}
          <Route path={routes.log.path} element={<Navigate to={routes.systemLog.path} replace />} />
          <Route path={routes.systemLog.path} element={withSuspense(SystemLog)} />
          <Route path={routes.campaignLog.path} element={withSuspense(CampaignLog)} />
        </Route>
      </Route>
      <Route element={<RedirectRoute />}>
        {/* 로그인 */}
        <Route path={routes.login.path} element={withSuspense(Login)} />
      </Route>
      <Route path={routes.root.path} element={<Navigate to={routes.dashboard.path} replace />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);

export default AppRouter;
