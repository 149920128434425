import { Response, ResponseSuccess } from 'api/types';

// 로그인
export type LoginData = {
  userId: string;
  password: string;
};

export enum UserRole {
  Read = 35, // 어드민 로그인 및 조회
  ApproveMedia = 36, // 매체 승인 메뉴 접근
  UpdateTags = 37, // 태그 수정 및 삭제 가능
  PartnersAdmin = 38, // 파트너스 페이지 어드민
  MonitoringAndCampaignReport = 39, // 기존 모니터링만 조회가능 -> 캠페인 > 리포트 접근 가능 권한 추가
}

export type LoginResponseSuccess = ResponseSuccess & {
  AccessToken: string;
  UserName: string;
  expiredAt: number; // UNIX TIMESTAMP (사용 X)
  RoleList: UserRole[];
};

export type LoginResponseFail = Response & {
  Result: false;
  ResultCode: 101 | 102;
};

// 비밀번호 변경
export type ChangePasswordData = {
  userId: string;
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
};

export type ChangePasswordResponseFail = Response & {
  Result: false;
  ResultCode: 103 | 104;
};
